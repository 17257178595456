import {
  logSentryError,
  useAnalytics,
} from '@chordcommerce/gatsby-theme-autonomy';
import { Link } from '@reach/router';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import { RelatedProductsResult } from '../../hooks/graphql/queries/use-related-products';
import { useCartContext } from '../CartContext/CartContext';
import { DisplayPrice } from '../DisplayPrice/DisplayPrice';
import { StyledQAProductCard } from './QuickAddProductCard.style';

export const QuickAddProductCard = React.forwardRef<
  HTMLDivElement,
  QuickAddProductCardProps
>(({ product, onItemAdd }, ref) => {
  const { sku, name, slug, mainImage, variants } = product;
  const [{ price, regularPrice }] = variants;
  const { addToCart, isEnrolmentInCart } = useCartContext();
  const { trackClickProduct } = useAnalytics();

  const handleProductAdd = async () => {
    try {
      await addToCart(sku);
      onItemAdd?.();
    } catch (error) {
      console.error(error);
      logSentryError(error, { source: 'QuickAddProductCard' });
    }
  };

  return (
    <StyledQAProductCard ref={ref}>
      <Link to={`/products/${slug}`} onClick={() => trackClickProduct(slug)}>
        {name}
      </Link>
      <Link to={`/products/${slug}`} onClick={() => trackClickProduct(slug)}>
        <GatsbyImage image={mainImage.gatsbyImageData} alt={mainImage.title} /> 
      </Link>
      <div className="flex-row">
        <button onClick={handleProductAdd} disabled={isEnrolmentInCart}>+</button>

        <DisplayPrice
          originalAmount={
            price !== regularPrice ? regularPrice.toFixed(2) : null
          }
          finalAmount={price.toFixed(2)}
        />
      </div>
    </StyledQAProductCard>
  );
});

interface QuickAddProductCardProps {
  product: RelatedProductsResult;
  onItemAdd?: () => void;
}
