import { useAnalytics, useCart } from '@chordcommerce/gatsby-theme-autonomy';
import { useMediaQuery, useScrollLock } from '@mantine/hooks';
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';
import useUser from '~/hooks/components/use-user';
import { exigo } from '~/utils/exigo';
import { useEnrolmentProduct } from '../../hooks/graphql/queries/use-enrolment-product';
import { AMBASSADOR_ENROLLMENT, AMBASSADOR_ENROLLMENT_MAXDATE } from '../../types/constants';
import { Collection, IProduct, Kit, ProductSubscription } from '../../types/types';
import { AmbassadorModal } from '../AmbassadorModal/AmbassadorModal';
import { graphql, useStaticQuery } from 'gatsby';
import { useLocalStorage } from '@mantine/hooks';

const catalogQuery = graphql`
  query Catalog {
    catalog: contentfulCatalog(slug: { eq: "catalog" }) {
      collections {
        ...CollectionDetailsFragment
      }
    }
  }
`;

const CartContext = createContext<CartContextData>({
  closeCart: () => {},
  isCartOpen: false,
  forceAmbassadorInfo: undefined,
  setForceAmbassadorInfo: () => {},
  openCart: () => {},
  toggleCart: () => {},
  isCartEmpty: true,
  enrollAmbassador: async () => {},
  isEnrolmentInCart: false,
  addToCart: async () => {},
  setShopWithId: async () => {},
  shopWithName: '',
  addToWishlist: async () => {},
  removeFromWishlist: async () => {},
  isSharedOrder: false,
  shareableLink: '',
  catalog: {
    collections: []
  },
  wishlistKits: [],
  wishlistProducts: [],
});

let lastAmbassadorSaved = -1;

const shareableCartRoutes = ['/account/ambassador/order'];

export const CartProvider: React.FC<PropsWithChildren<CartProviderProps>> = ({
  children,
}) => {
  let path = typeof window !== 'undefined' ? window.location.pathname : '';
  const ambassadorProduct = useEnrolmentProduct(); // used only for notice content in mini cart + modal notice when adding to cart
  const { trackViewCart } = useAnalytics();
  const {catalog} = useStaticQuery<{ catalog: {collections: Collection[]; }}>(catalogQuery);
  const isSharedOrder = typeof window !== "undefined" && shareableCartRoutes.some((p) => path.includes(p));
  const {
    cart,
    modifyCart,
    forgetCart,
    subscribeProduct,
    addToCart: chordATC,
    removeFromCart
  } = useCart();
  const { user } = useUser();
  const [shopWithName, setShopWithName] = useState<string>('');
  const [isFetchingShopWith, setIsShopWithFetching] = useState<boolean>(false);
  const [showAmbassadorCartClearNotice, setShowAmbassadorCartClear] = useState(false);
  const [wishlistSkus, setWishlistSkus] = useLocalStorage<string[]>({key: 'shareableCart', defaultValue: []});
  const [wishlistProducts, setWishlistProducts] = useState<IProduct[]>([])
  const [wishlistKits, setWishlistKits] = useState<Kit[]>([]);
  const [shareableLink, setShareableLink] = useState<string>('');
  const [isCartOpen, setCartOpen] = useState(false);
  const [forceAmbassadorInfo, setForceAmbassadorInfo] = useState(undefined);
  const isMobile = useMediaQuery('(max-width: 768px)');
  const [allProducts] = useState<IProduct[]>(() => {
    const flatMap = catalog.collections.flatMap(c => c.products.map(p => p));

    return flatMap.filter((p, i) => {
      return !p.hidden && flatMap.findIndex(c => c.slug === p.slug) === i;
    });
  });
  const [allKits] = useState<Kit[]>(() => {
    const flatMap = catalog.collections.flatMap(c => c.kits?.map(p => p));

    return flatMap.filter((p, i) => {
      return !p?.hidden && flatMap.findIndex(c => c?.slug === p?.slug) === i;
    }).filter(el => el);
  });

  useScrollLock(isMobile && isCartOpen);
  useEffect(() => {
    if (user && cart && cart?.data?.userId && user?.data?.id && `${cart.data.userId}` !== `${user.data.id}`) {
      forgetCart();
    }
  }, [cart, user])
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const isAmbassadorPortal = window.location.pathname.includes(
        '/account/ambassador'
      );
      const currentUserId = user?.data?.id;

      if (isAmbassadorPortal && !currentUserId) {
        return;
      }

      const cartAmbassador =
        cart?.data?.metadata?.ambassador_id ||
        cart?.data?.metadata?.ambassadorId || 
        (user as any)?.data?.metadata?.ambassador_id || 
        (user as any)?.data?.metadata?.ambassadorId
      let savedAmbassador = isAmbassadorPortal
        ? currentUserId
        : new URLSearchParams(window.location.search).get('amref') ||
          cartAmbassador;

      if (!savedAmbassador) {
        try {
          savedAmbassador = localStorage.getItem('ambassadorId');
        } catch (e) {
          console.error(e);
        }
      }
      if (savedAmbassador) {
        try {
          if (!isAmbassadorPortal) {
            window.localStorage.setItem('ambassadorId', savedAmbassador);
          }
        } catch (e) {
          console.error(e);
        }
        if (
          `${savedAmbassador}` !== `${cartAmbassador}` &&
          `${lastAmbassadorSaved}` !== `${savedAmbassador}`
        ) {
          lastAmbassadorSaved = savedAmbassador;
          modifyCart({
            metadata: {
              ambassador_id: savedAmbassador,
              ambassadorId: savedAmbassador, // Chord expects both :(
            },
          });
        }
      }
      setShopWithId(savedAmbassador);
    }
    const savedAmbassadorId = localStorage.getItem('ambassadorId');
  }, [cart, user]);

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }
    document.body.classList[isCartOpen ? 'add' : 'remove']('cart-open');
  }, [isCartOpen])

  useEffect(() => {
    setWishlistKits(allKits.filter((k) => wishlistSkus.some((sku) => sku === k.sku)));
    setWishlistProducts(allProducts.filter((p) => wishlistSkus.some((sku) => sku === p.sku)));
  }, [wishlistSkus]);

  useEffect(function buildShareableLink() {
    if (!wishlistKits.length && !wishlistProducts.length) return;
    let link = `${window.location.host}/wishlist?`

    const params = new URLSearchParams({ 
      items: wishlistSkus.join(';'),
      amref: user?.data?.id
    });

    setShareableLink(link + params.toString());
  }, [wishlistSkus, wishlistProducts, wishlistKits, user?.data]);

  const closeCart = () => {
    setCartOpen(false);
  };

  const toggleCart = () => {
    setCartOpen(prev => !prev);
  };

  const openCart = async () => {
    setCartOpen(true);
    await trackViewCart();
  };

  const addToWishlist: CartContextData['addToWishlist'] = (sku) => {
    if (wishlistSkus.includes(sku)) return;
    setWishlistSkus(prev => [...prev, sku]);
    openCart();
  }

  const removeFromWishlist: CartContextData['removeFromWishlist'] = (sku: string) => {
    setWishlistSkus(prev => {
      return prev.filter((skuInCart) => skuInCart !== sku)
    });
  }

  const addToCart = async (
    sku: string,
    quantity = 1,
    isSubscribing = false,
    subscriptionInterval?: ProductSubscription['intervals'][0]
  ) => {
    const lineItem = cart.data?.lineItems?.find(li => li.variant.sku === sku);
    const isItemInCartASubscription = !!lineItem?.subscriptionLineItems.length;

    if (!!lineItem && isSubscribing === isItemInCartASubscription) {
      await openCart();
      return;
    }

    if (isEnrolmentInCart) {
      return;
    }

    if (!!lineItem && isSubscribing !== isItemInCartASubscription) {
      // remove from cart if mixing subscriptions and one-time purchases of same product
      // i.e. If you have a subscription in your cart for this product and you add a one time purchase, convert to OTP
      await removeFromCart(lineItem.id);
    }

    try {
      if (isSubscribing && !subscriptionInterval) {
        throw new Error(
          'Please provide the subscription interval if you are subscribing'
        );
      }

      if (isSubscribing) {
        await subscribeProduct({
          sku,
          interval: subscriptionInterval,
          quantity,
        });
      } else {
        await chordATC(sku, quantity);
      }
    } catch (e) {
      console.error(e);
    }
    await openCart();
  };

  const enrollAmbassador = async () => {
    const now = new Date();
    if (now.getTime() > AMBASSADOR_ENROLLMENT_MAXDATE.getTime()) {
      return;
    }
    try {
      if (cart.data.lineItems.length > 0) {
        setShowAmbassadorCartClear(true);
        return;
      }

      // ! this promise won't resolve which is preventing a smooth cart reveal
      // i.e the cart opens while content is changing
      chordATC(AMBASSADOR_ENROLLMENT.sku, 1);

      await openCart();
    } catch (err) {
      console.error(err);
    }
  };

  const setShopWithId = async (userId: string) => {
    if (!userId || isFetchingShopWith) {
      return;
    }
    setIsShopWithFetching(true);
    try {
      const { name } = await exigo(`ambassador?id=${userId}`)
      setShopWithName(name);
    }
    catch (e) {
      console.error(e);
    } finally {
      setIsShopWithFetching(false);
    }
  }

  // used when a user adds the enrolment to the cart when they have line items.
  // their confirmation will remove the items from the cart and add the ambassador enrolment
  const confirmAmbassadorEnrolment = async () => {
    try {
      await forgetCart();
      chordATC(AMBASSADOR_ENROLLMENT.sku, 1);
      setShowAmbassadorCartClear(false);
      await openCart();
    } catch (err) {
      console.error(err);
    }
  };

  const isEnrolmentInCart = !!cart.data?.lineItems?.find(
    item => item.variant.slug === AMBASSADOR_ENROLLMENT.slug
  );

  const value: CartContextData = {
    isCartOpen,
    closeCart,
    forceAmbassadorInfo,
    setForceAmbassadorInfo,
    toggleCart,
    openCart,
    isCartEmpty: !cart.data?.lineItems?.length,
    enrollAmbassador,
    isEnrolmentInCart,
    ambassadorProduct,
    wishlistKits,
    wishlistProducts,
    addToCart,
    setShopWithId,
    shopWithName,
    addToWishlist,
    removeFromWishlist,
    isSharedOrder,
    shareableLink,
    catalog: catalog
  };

  return (
    <CartContext.Provider value={value}>
      {children}
      <AmbassadorModal
        isOpen={showAmbassadorCartClearNotice}
        onClose={() => setShowAmbassadorCartClear(false)}
        onConfirm={confirmAmbassadorEnrolment}
      />
    </CartContext.Provider>
  );
};

interface CartContextData {
  isCartOpen: boolean;
  isCartEmpty: boolean;
  openCart: () => void;
  closeCart: () => void;
  toggleCart: () => void;
  setShopWithId: (userId: string) => Promise<void>;
  enrollAmbassador: () => Promise<void>;
  forceAmbassadorInfo: number;
  setForceAmbassadorInfo: (force: number) => void;
  ambassadorProduct?: IProduct;
  isEnrolmentInCart: boolean;
  shopWithName: string;
  addToCart: (
    sku: string,
    quantity?: number,
    isSubscribing?: boolean,
    subscriptionInterval?: ProductSubscription['intervals'][0]
  ) => Promise<void>;
  isSharedOrder: boolean;
  addToWishlist: (sku: string) => void;
  removeFromWishlist: (sku: string) => void;
  shareableLink: string;
  catalog: { collections: Collection[]; };
  wishlistKits: Kit[];
  wishlistProducts: IProduct[];
}

interface CartProviderProps {}

export const useCartContext = () => useContext(CartContext);

export const CartConsumer = CartContext.Consumer;
