import { useMediaQuery } from '@mantine/hooks';
import React from 'react';
import { useCartContext } from '~/components/CartContext/CartContext';
import { StyledCartItem } from '~/components/CartItem/CartItem.style';
import { DisplayPrice } from '~/components/DisplayPrice/DisplayPrice';
import { Cta } from '~/components/Generic/Cta/Cta';
import { IProduct, Kit } from '~/types/types';

export const SharedCartItem: React.FC<SharedCartItemProps> = ({ data }) => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const { removeFromWishlist } = useCartContext();
  const variant = (data as IProduct).variants?.find(v => v.sku === data.sku);
  const price = variant?.regularPrice || (data as Kit).regularPrice

  const handleRemove = () => {
    removeFromWishlist(data.sku);
  }
  return (
    <StyledCartItem isSharedOrder isEnrolment={false}>
      <div className="img-container">
        {data.mainImage?.gatsbyImageData && (
          <img src={data.mainImage.url} alt={data.name} />
        )}
      </div>
      <div className="content">
        <div className="item-header">
          <div className="item-name">
            <p>{data.name}</p>
          </div>
          <DisplayPrice
            originalAmount={`${price}`}
            finalAmount={null}
            inlinePrice={!isMobile}
          />
        </div>
        <div className="item-controls">
          <Cta
            href="#"
            variant="secondary"
            onClick={handleRemove}
            className="remove-btn"
          >
            Remove
          </Cta>
        </div>
      </div>
    </StyledCartItem>
  );
};

interface SharedCartItemProps {
  data: IProduct | Kit;
}
