import { useCart } from '@chordcommerce/gatsby-theme-autonomy';
import { useStaticQuery, graphql } from 'gatsby';
import { ContentfulImageFile, GatsbyImageData } from '../../../types/types';

export const useRelatedProducts = (): RelatedProductsResult[] => {
  const { cart } = useCart();

  const skusInCart = cart.data?.lineItems?.map(({ variant }) => variant.sku);

  const relatedProductsQuery = graphql`
    query RelatedProducts {
      allContentfulProduct {
        edges {
          node {
            sku
            hidden
            name
            slug
            sku
            mainImage {
              title
              gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 575)
            }
            variants {
              price
              regularPrice
            }
          }
        }
      }
    }
  `;

  // Maybe want to query related products and work out a more sophisticated sorting method
  // but right now there are ~10 products so
  const { allContentfulProduct } = useStaticQuery(relatedProductsQuery);

  const ret = allContentfulProduct.edges
    .filter(({ node }: any) => !skusInCart?.includes(node.sku) && !node.hidden)
    .map(({ node }: any) => node)
    .flat() as RelatedProductsResult[];

  return ret;
};

export interface RelatedProductsResult {
  mainImage: {
    gatsbyImageData: GatsbyImageData;
    title: string;
  };
  name: string;
  sku: string;
  slug: string;
  variants: {
    price: number;
    regularPrice: number;
  }[];
}
