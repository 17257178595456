import React from 'react';
import styled from 'styled-components';
import { ThemeColor } from '~/types/types';
import { pxtorem } from '../../utils/tools';

export const StyledModal = styled.div<StyledModalProps>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0px;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  z-index: 40;

  .modal--close {
    width: auto;
    top: 20px;
    right: 22px;
  }

  .modal--card {
    border-radius: 10px;
    background-color: var(--${({ bg }) => bg });
    position: relative;
    padding: ${({padding}) => typeof padding === "undefined" ? `calc(var(--gutter) * 3) var(--gutter)` : padding};
    width: calc(100vw - var(--gutter) * 2);
    max-width: ${({maxWidth}) => maxWidth ? `${maxWidth}px` : `calc(1920px - var(--gutter))`};
    opacity: 0;
    transform: translateY(15%);
    
    ${({scrollable}) => scrollable && `overflow-y: scroll;`};
    @media (min-width: 768px) {
      padding: ${({padding}) => typeof padding === "undefined" ? `${pxtorem(48)} ${pxtorem(22)}` : padding};
    }
  }

  .modal--bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #1f1f1f;
    opacity: 0;
  }

  .modal--close {
    position: absolute;
    text-decoration: none;
    top: min(5.75%, 23px);
    right: min(5%, 23px);
    font-weight: 300;
    font-size: ${pxtorem(16)};
    @media (min-width: 768px) {
      font-size: ${pxtorem(20)};
    }

    &:after {
      height: 1px;
      bottom: -3px;
    }
  }

  ${({fullScreenMobile, breakpoint}) => fullScreenMobile && `
      @media (max-width: ${breakpoint || 768}px) {
        .modal--card {
          border-radius: 0;
          width: 100vw;
          height: 100vh;
          overflow-y: scroll;
          padding-bottom: 24px;
        }
    `}
`;

interface StyledModalProps {
  bg: ThemeColor;
  maxWidth?: number;
  padding?: React.HTMLAttributes<HTMLDivElement>['style']['padding'];
  fullScreenMobile?: boolean;
  breakpoint?: number;
  scrollable?: boolean;
}