import { useClickOutside, useScrollLock, useWindowEvent, useWindowScroll } from '@mantine/hooks';
import React, { PropsWithChildren } from 'react';
import CloseSVG from '~/assets/images/icons/close.svg';
import { PanelOrigin } from '../../../types/types';
import { StyledPanel } from './Panel.style';

export const Panel: React.FC<PropsWithChildren<SidePanelProps>> = ({
  children,
  isOpen,
  onClose,
  panelOrigin,
  isDrawer = true,
}) => {
  const [scroll] = useWindowScroll();
  const top = scroll.y > 63 ? 56 : 86;
  useScrollLock(isOpen);
  useWindowEvent('keydown', (ev: KeyboardEvent) => {
    if (ev.key === 'Escape') {
      onClose();
    }
  });
  const clickRef = useClickOutside(onClose);

  return (
    <StyledPanel panelOrigin={panelOrigin} isOpen={!isDrawer || isOpen} top={top}>
      <div className={`panel-bg ${isOpen ? 'open' : ''}`} />
      <div className={`panel ${isOpen ? 'open' : ''}`} ref={clickRef}>
        <div className="btn-space">
          <button className="close-btn" onClick={onClose}>
            <CloseSVG />
          </button>
        </div>
        {children}
      </div>
    </StyledPanel>
  );
};

interface SidePanelProps {
  isOpen: boolean;
  onClose: () => void;
  panelOrigin: PanelOrigin;
  isDrawer?: boolean;
}
