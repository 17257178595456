import React from 'react';
import { DefaultTheme } from 'styled-components';
import { Link } from 'gatsby';
import { useDisclosure } from '@mantine/hooks';
import LogoSvg from '~/assets/images/logos/logo.svg';
import { Wrapper } from '~/styles/Wrapper.styled';
import { PageItem, SubMenu } from '../Header';
import { Submenu } from '../Submenu/Submenu';
import { StyledDesktopNav } from './DesktopNav.style';
import { useCartContext } from '../../CartContext/CartContext';
import ProfileIcon from '../../../assets/images/icons/profile.svg';
import { CartBadgeButton } from '~/components/Cart/CartBadgeButton/CartBadgeButton';
import { AMBASSADOR_ENROLLMENT_MAXDATE } from '~/types/constants';

const now = new Date();


export const DesktopNav: React.FC<DesktopNavProps> = ({
  pages,
  shopSubmenu,
  quicklinks,
  isFixed,
}) => {
  const [isSubmenuOpened, subMenuHandlers] = useDisclosure(false);
  const { toggleCart } = useCartContext();
  return (
    <StyledDesktopNav
      isFixed={isFixed}
      className={isSubmenuOpened ? 'submenu--opened' : ''}
    >
      <div
        className={`submenu__backdrop ${
          isSubmenuOpened ? 'submenu__backdrop--opened' : ''
        }`}
        onMouseEnter={() => subMenuHandlers.close()}
      />

      <Wrapper gutter={false} className="desktopnav__wrapper" width="wide">
        <nav className="desktopnav__navs">
          <div className="desktopnav__nav desktopnav__nav--left">
            <div className="logo">
              <Link to="/">
                <LogoSvg />
              </Link>
            </div>
          </div>
          <div className="desktopnav__nav desktopnav__nav--center">
            <ul>
              {pages?.filter(({slug}) => slug === 'join' && now.getTime() > AMBASSADOR_ENROLLMENT_MAXDATE.getTime() ? false : true).map(({ slug, name }) => (
                <li key={slug}>
                  {slug == 'shop' ? (
                    <>
                      <Link
                        to={`/${slug}`}
                        onMouseEnter={() => subMenuHandlers.open()}
                        partiallyActive
                        activeClassName="active"
                      >
                        {name}
                      </Link>
                      {<Submenu {...shopSubmenu} />}
                    </>
                  ) : (
                    <Link
                      to={`/${slug}`}
                      onMouseEnter={() => subMenuHandlers.close()}
                      partiallyActive
                      activeClassName="active"
                    >
                      {name}
                    </Link>
                  )}
                </li>
              ))}
            </ul>
          </div>
          <div className="desktopnav__nav desktopnav__nav--right">
            <ul>
              {quicklinks.map(({ slug, name }) => {
                const isCartLink = slug === '/cart';
                const isProfileLink = slug === '/account';
                return (
                  <li key={slug}>
                    {isCartLink ? (
                      <a
                        href="#"
                        onClick={e => {
                          e.preventDefault();
                          toggleCart();
                        }}
                      >
                        <CartBadgeButton name={name} />
                      </a>
                    ) : (
                      <Link to={slug}>
                        {isProfileLink ? <ProfileIcon title={name} /> : name}
                      </Link>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        </nav>
      </Wrapper>
    </StyledDesktopNav>
  );
};

interface DesktopNavProps {
  pages: PageItem[];
  shopSubmenu: SubMenu;
  quicklinks: PageItem[];
  isFixed?: boolean;
}
