import { Link } from 'gatsby';

import useFooterQuery from '~/hooks/graphql/queries/use-footer';
import LogoSvg from '~/assets/images/logos/logo-white.svg';
import FooterNewsletterSignup from '~/components/Footer/NewsletterSignup/NewsletterSignup';
import { Wrapper } from '~/styles/Wrapper.styled';
import { StyledFooter } from './Footer.style';

import Shape5 from '~/assets/images/shapes/shape5.svg';
import { useLocation } from '@reach/router';
import { AMBASSADOR_ENROLLMENT_MAXDATE } from '~/types/constants';

const now = new Date();


export const Footer = ({ showMarquee = true }) => {
  const { footerSubmenu } = useFooterQuery();
  const location = useLocation();
  const isCheckout = location?.pathname?.includes?.('checkout');
  return <StyledFooter className={isCheckout ? 'is-checkout' : null}>
    {showMarquee && (
      <div className="footer__marquee">
        <div className="footer__marquee__inner">
          <div className="footer__marquee__line">
            <span>#worthsharing</span>
            <Shape5 />
            <span>#ownyourday</span>
            <Shape5 />
            <span>#wearecommons</span>
            <Shape5 />
          </div>

          <div className="footer__marquee__line">
            <span>#worthsharing</span>
            <Shape5 />
            <span>#ownyourday</span>
            <Shape5 />
            <span>#wearecommons</span>
            <Shape5 />
          </div>
        </div>
      </div>
    )}
    <Wrapper className="footer__wrapper">
      <div className="footer__content">
        <div className="footer__columnWrapper">
          <div className="footer__column footer__column--newsletter">
            <FooterNewsletterSignup />
          </div>

          {footerSubmenu.columns.map((column, i) => (
            <div
              key={column.id}
              className={`footer__column footer__column--${i}`}
            >
              <p className="footer__columnTitle">{column.title}</p>
              <ul className="footer__list">
                {column?.items?.filter(({slug}) => slug === 'join' && now.getTime() > AMBASSADOR_ENROLLMENT_MAXDATE.getTime() ? false : true).map(({ slug, name, url }, ii) => (
                  <li
                    className={`footer__listItem`}
                    key={`footer-${i}-${ii}`}
                  >
                    <Link to={url || `/${slug}`}>{name}</Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        <div className="footer__logo">
          <LogoSvg />
          <div>
            <a href="mailto:help@commons.co">help@commons.co</a>
          </div>
        </div>
      </div>
      <div className="footer__copyrights">© 2022 Commons</div>
    </Wrapper>
  </StyledFooter>
};

export default Footer;

interface FooterProps {
  showMarquee?: boolean;
}
