import styled from 'styled-components';
import { pxtorem } from '~/utils/tools';

export const StyledFooter = styled.footer`
  background: var(--green);
  color: var(--white);
  font-weight: 400;

  &.is-checkout {
    display: none;
  }

  @media (min-width: 768px) {
    font-size: ${pxtorem(20)};
  }

  a {
    color: var(--white);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      text-decoration-thickness: 1px;
    }
  }

  .footer__marquee {
    --offset: 20vw;
    --move-initial: calc(-25% + var(--offset));
    --move-final: calc(-75% + var(--offset));
    --speed: 50s;

    position: relative;
    overflow: hidden;
    white-space: nowrap;
    height: 40vw;

    @media (min-width: 768px) {
      height: 35vw;
    }

    font-family: var(--secondaryFont);
    font-style: italic;
    font-weight: 300;
    font-size: 20vw;
    letter-spacing: -0.02em;

    .footer__marquee__inner {
      display: flex;
      width: fit-content;
      position: relative;
      transform: translateX(var(--move-initial));
      animation: marquee var(--speed) linear infinite;

      @media (min-width: 768px) {
        margin-top: 2rem;
      }
    }

    .footer__marquee__line {
      display: flex;
      align-items: center;
      svg {
        width: 1rem;
        aspect-ratio: 1 / 1;
        margin: 0 3rem;
        fill: var(--white);
      }
    }
    @keyframes marquee {
      0% {
        transform: translateX(var(--move-initial));
      }
      100% {
        transform: translateX(var(--move-final));
      }
    }

    @media (min-width: 768px) {
      font-size: 11vw;
      height: 20vw;
    }
  }

  .footer__content {
    @media (min-width: 1200px) {
      padding: 0 calc(var(--gutter) * 3);
    }
  }

  .footer__columnWrapper {
    display: flex;
    justify-content: space-between;

    @media (max-width: 767px) {
      flex-wrap: wrap;
    }
  }

  .footer__column {
    flex: 1 0 100%;

    @media (min-width: 768px) {
      flex: 1 1; // Safari fix
      flex: 1 1 max-content;
      padding: 0 var(--gutter);
    }

    &--newsletter {
      padding: 0 calc(var(--gutter) * 2);
      margin-bottom: calc(var(--gutter) * 3);
      @media (min-width: 768px) {
        padding: 0;
        margin-bottom: 0;
      }

      @media (min-width: 1200px) {
        padding: 0 20% 0 0;
        flex: 1 1 40%;
      }
    }

    &--0,
    &--1 {
      @media (max-width: 767px) {
        flex: 1 1 50%;
      }
    }

    &--1 {
      @media (max-width: 767px) {
        text-align: right;
      }
    }

    &--2 {
      @media (max-width: 767px) {
        font-size: ${pxtorem(12)};
        margin-top: 40px;

        .footer__columnTitle {
          display: none;
        }

        .footer__list {
          columns: 2;
        }

        // Select the last half of items, works up to 6.
        .footer__listItem:first-child:last-child,
        .footer__listItem:nth-child(n + 2):nth-last-child(-n + 2),
        .footer__listItem:nth-child(n + 3):nth-last-child(-n + 3),
        .footer__listItem:nth-child(n + 4):nth-last-child(-n + 4),
        .footer__listItem:nth-child(n + 5):nth-last-child(-n + 5),
        .footer__listItem:nth-child(n + 6):nth-last-child(-n + 6) {
          text-align: right;
        }
      }
    }
  }

  .footer__columnTitle {
    text-transform: uppercase;
    line-height: 1.5;
    margin-bottom: 0;
    @media (min-width: 768px) {
      margin-bottom: 16px;
    }
  }

  .footer__list {
    list-style: none;
  }

  .footer__listItem {
    line-height: 2;
  }

  .footer__logo {
    margin: 3rem 0;
    text-align: center;
    font-size: ${pxtorem(20)};

    svg {
      height: 17px;
      width: auto;
    }

    @media (min-width: 768px) {
      margin: 6rem 0 0 0;
      text-align: left;
    }
  }

  .footer__copyrights {
    text-align: center;
    opacity: 0.3;

    @media (min-width: 768px) {
      font-size: ${pxtorem(16)};
      opacity: 1;
      padding: 1rem 0 0 0;
    }
  }
`;
