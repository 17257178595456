import { Link } from 'gatsby';
import styled, { css } from 'styled-components';

import { desktopHover, pxtorem } from '~/utils/tools';

const DefaultCss = css`
  cursor: pointer;
  transition: 0.25s;
  transition-property: border, background, color;
  display: inline-block;
  background: transparent;
  border: 1px solid var(--text);
  padding: 15px 26px;
  border-radius: 114px;
  text-decoration: none;
  color: var(--color);
  font-weight: 400;
  text-align: center;

  &[disabled] {
    cursor: not-allowed;
    pointer-events: none;
  }

  ${desktopHover(`
    background: var(--black);
    border-color: var(--black);
    color: var(--white);
  `)}

  &.cta--small {
    padding: 10px 15px;
    font-size: ${pxtorem(12)};
    font-weight: 400;
  }

  &.cta--large {
    padding: 20px 36px;
    font-size: ${pxtorem(20)};
    font-weight: 500;
    border-width: 2px;
  }

  &.cta--primary {
    background: var(--orange);
    border-color: var(--orange);
    color: var(--white);

    ${desktopHover(`
      background: var(--orangeDark);
      border-color: var(--orangeDark);
    `)}

    &[disabled] {
      opacity: 0.75;
    }
  }

  &.cta--secondary {
    position: relative;
    padding: 0;
    border: 0;
    font-weight: 500;
    text-decoration: none;
    transition-property: color 250ms;;
    border-radius: 0;


    &:after {
      transition: transform 0.25s var(--cubic-ease);
      content: "";
      position: absolute;
      bottom: -5px;
      left: 0;
      right: 0;
      height: 2px;
      background: currentColor;
      transform: scaleX(1);
      transform-origin: left center;
    }

    ${desktopHover(`
      background: transparent;
      color: var(--green);

      &:after {
        transform-origin: right center;
        transform: scaleX(0);
      }
    `)}
  }

    &.cta--invert {
      border-color: var(--white);
      color: var(--white);

      ${desktopHover(`
      background: var(--white);
      color: var(--black);
    `)}
    }

    @media (max-width: 500px) {
      &:not(.cta--secondary) {
        width: 100%;
      }
    }

    .cta__inner {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      overflow: visible;
    }

    .cta__label {
      display: flex;
      align-items: center;
    }

    .cta__icon {
      display: flex;
      align-items: center;

      &.cta__icon--left {
        margin-right: 10px;
      }

      &.cta__icon--right {
        margin-left: 10px;
      }
    }
  }
`;

export const StyledA = styled.a<CtaProps>`
  ${DefaultCss}
`;

export const StyledLink = styled(Link)`
  ${DefaultCss}
`;

export const StyledBtn = styled.button<CtaProps>`
  background: transparent;
  border: none;
  ${DefaultCss}
`;

interface CtaProps {}
