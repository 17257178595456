import { useMediaQuery } from '@mantine/hooks';
import React, { useRef } from 'react';
import { Navigation, Scrollbar, SwiperOptions } from 'swiper';
import 'swiper/css';
import 'swiper/css/scrollbar';
import { Swiper, SwiperSlide } from 'swiper/react';
import { RelatedProductsResult } from '../../hooks/graphql/queries/use-related-products';
import { QuickAddProductCard } from '../QuickAddProductCard/QuickAddProductCard';
import { StyledUpsellCarousel } from './UpsellCarousel.style';
import Arrow from '~/assets/images/icons/arrow-2.svg';
import { useSwiperRef } from "~/hooks/components/use-swiper-ref";

const SWIPER_OPTIONS: SwiperOptions = {
  loop: false,
  preventClicksPropagation: false,
  touchStartPreventDefault: false,
  slidesPerView: 'auto',
  grabCursor: true,
  modules: [Scrollbar, Navigation],
  updateOnWindowResize: true,
  scrollbar: {
    draggable: true,
    dragSize: 109,
  },
  spaceBetween: 10,
};

export const UpsellCarousel: React.FC<UpsellCarouselProps> = ({
  products,
  onItemAdd,
}) => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  if (products.length === 0) return null;

  const [prevEl, prevElRef] = useSwiperRef<HTMLButtonElement>();
  const [nextEl, nextElRef] = useSwiperRef<HTMLButtonElement>();

  const swiperOptions = {
    ...SWIPER_OPTIONS,
    navigation: {
      nextEl: nextEl,
      prevEl: prevEl
    },
  }

  return (
    <StyledUpsellCarousel maxCardHeight={isMobile ? 190 : 261}> 
      <div className="upsellCarousel__title">
        <span className="small-label">Pair it With</span>

        <div className="SwiperNav SwiperNav--small">
          <button type="button" className="SwiperNav__item SwiperNav__item--prev" ref={prevElRef}>
            <Arrow />
          </button>

          <button type="button" className="SwiperNav__item SwiperNav__item--next" ref={nextElRef}>
            <Arrow />
          </button>
        </div>
      </div>

      <Swiper {...swiperOptions} className="carousel">
        {products.slice(0, 8).map((product, i) => {

          return (
            <SwiperSlide key={product.slug}>
              <QuickAddProductCard
                product={product}
                onItemAdd={onItemAdd}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </StyledUpsellCarousel>
  );
};

interface UpsellCarouselProps {
  products: RelatedProductsResult[];
  onItemAdd?: () => void;
}
