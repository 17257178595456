import { useState } from 'react';
import { FieldValues, OnSubmit, useForm } from 'react-hook-form';
import {
  useAnalytics,
  logSentryError,
} from '@chordcommerce/gatsby-theme-autonomy';

import { trackEmailCaptured } from '@chordcommerce/gatsby-theme-autonomy/src/services/analytics';

import { StyledBtn } from '~/components/Generic/Cta/Cta.style';
import { StyledNewsletterSignup } from './NewsletterSignup.style';
import { graphql, useStaticQuery } from 'gatsby';

const FooterNewsletterSignup: React.FC = () => {
  const {contentfulNewsletter} = useStaticQuery(graphql`{
    contentfulNewsletter {
      callToAction
    }
  }`);
  const { register, errors, handleSubmit } = useForm();
  const [submitted, setSubmitted] = useState(false);
  const onSubmit: OnSubmit<FieldValues> = async (data, e) => {
    e?.preventDefault();
    const { email } = data;

    const payload = {
      email: email,
      placement: {
        page: 'all-pages',
        component: 'footer',
        website: window.location.hostname,
      },
    };

    try {
      trackEmailCaptured(payload);
      setSubmitted(true);
    } catch (err) {
      logSentryError(err, { source: 'FooterNewsletterSignup' });
    }
  };

  return (
    <StyledNewsletterSignup>
      <div>
        <p>
          {contentfulNewsletter.callToAction}
        </p>

        {!submitted && (
          <div>
            <form onSubmit={() => handleSubmit(onSubmit)()}>
              <div>
                <input
                  name="email"
                  type="email"
                  title="Email"
                  placeholder="Enter your email"
                  aria-label="Email"
                  ref={register({
                    required: true,
                    pattern: {
                      value: /^\S+@\S+$/i,
                      message: 'Please enter a valid email address.',
                    },
                  })}
                />
              </div>
              {errors && errors['email'] && (
                <p>
                  {errors['email'].type === 'required' &&
                    'Email missing. Please enter your email.'}

                  {errors['email'].type === 'pattern' &&
                    'Please enter a valid email address.'}
                </p>
              )}
              <StyledBtn className="cta--invert" type="submit">
                Subscribe
              </StyledBtn>
            </form>
          </div>
        )}

        {submitted && <div>Thanks for signing up!</div>}
      </div>
    </StyledNewsletterSignup>
  );
};

export default FooterNewsletterSignup;
