import styled from 'styled-components';
import { pxtorem } from '../../../utils/tools';

export const StyledDropdown = styled.ul<StyledDropdownProps>`
  --x-pad: ${pxtorem(22)};
  --y-pad: ${pxtorem(16)};
  --min-width: ${pxtorem(190)};

  border-radius: ${({ isOpen }) => (isOpen ? `20px 20px 0px 0px` : `100px`)};
  border: 1px solid var(--black);
  ${({ isOpen }) => isOpen && `border-bottom: none;`}

  max-height: ${({ isOpen }) => (isOpen ? 'auto' : `55px`)};
  width: 100%;
  min-width: var(--min-width);
  height: 100%;
  position: relative;
  z-index: 5;
  color: var(--black);

  &:hover,
  &:focus,
  &:active,
  &:focus-within {
    .dropdown-option button {
      background-color: var(--lime);
    }
  }

  .dropdown-value {
    display: flex;
    align-items: center;
    font-size: ${pxtorem(16)};
    line-height: 117%;
    white-space: nowrap;

    button {
      padding: var(--y-pad) var(--x-pad);
      width: 100%;
      height: 100%;
      border: none;
      background-color: transparent;
      text-align: left;
      cursor: pointer;
      color: inherit;

    ${({ isOpen }) =>
      isOpen &&
      `
      color: var(--blackHover);
    `};
    }


    @media (min-width: 768px) {
      font-size: ${pxtorem(20)};
    }
  }

  svg {
    position: absolute;
    right: calc(var(--x-pad) - 4px);
  }

  .dropdown-options {
    pointer-events: ${({ isOpen }) => (isOpen ? 'all' : 'none')};
    width: ${({ width }) => (width ? `${width}px` : '100%')};
    min-width: var(--min-width);
    background: var(--white);
    list-style: none;
    position: absolute;
    top: 100%;
    left: -1px;
    border: 1px solid var(--black);
    border-top: none;
    border-radius: 0 0 20px 20px;
    opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
    z-index: 5;
    overflow: hidden;

    li {
      button {
        padding: calc(var(--y-pad)) var(--x-pad);
        font-size: ${pxtorem(20)};
        color: inherit;

        @media (max-width: 768px) {
          font-size: ${pxtorem(16)};
        }
      }
      width: 100%;
      transition: background-color 0.2s var(--cubic-ease);

      &:hover {
        background-color: var(--lime);
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    button {
      cursor: pointer;
      background-color: transparent;
      border: none;
      text-align: left;
      padding: 0;
      width: 100%;
      height: 100%;
    }
  }

  @media (max-width: 1024px) {
    --max-width: 160px;
  }

  @media (max-width: 768px) {
    max-height: ${pxtorem(40)};
    --x-pad: ${pxtorem(18)};
    --y-pad: ${pxtorem(10)};
  }

  @media (max-width: 480px) {
    width: 100%;
    --min-width: unset;
  }
`;

interface StyledDropdownProps {
  isOpen: boolean;
  width: number;
}
