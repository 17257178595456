import React, { PropsWithChildren } from 'react';
import { StyledBadgeButton } from '~/components/BadgeButton/BadgeButton.styled';

export const BadgeButton: React.FC<BadgeButtonProps> = ({
  badgeContent,
  children,
  ...props
}) => {
  return (
    <StyledBadgeButton badgeContent={badgeContent} {...props} className={!!badgeContent ? 'show' : ''}>
      {children}
    </StyledBadgeButton>
  );
};

interface BadgeButtonProps
  extends PropsWithChildren<React.HTMLAttributes<HTMLButtonElement>> {
  badgeContent: number | string;
}
