import React, { FC } from 'react'
import { Helmet } from 'react-helmet'
import useMetadata from '~/hooks/components/use-metadata'
import { Any } from '../../types/types'

const Metadata: FC<Any> = ({ description, image, lang = 'en', meta = [], title }) => {
  /*
   *  This is the order of precedence used by `useMetadata` to determine the page's metadata:
   *
   *  1. First, we look for a pageMetadata entry in Contentful with a matching path.
   *  2. Second, we look at the props passed to this component.
   *  3. Third, we look at the default pageMetadata entry in Contentful.
   */
  const { metaTitle, metaDescription, metaImage, metaUrl } = useMetadata({
    description,
    image,
    title,
  })

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      meta={[
        {
          name: `title`,
          content: metaTitle,
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: metaImage,
        },
        {
          property: `og:url`,
          content: metaUrl,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:url`,
          content: metaUrl,
        },
      ].concat(meta)}
      link={[
        {
          rel: 'icon',
          href: '/favicon.ico',
        },
      ]}
    />
  )
}



export default Metadata
