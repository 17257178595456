import { graphql, useStaticQuery } from 'gatsby';
import { IProduct } from '~/types/types';

// The Notice info is in the productType object
export const useEnrolmentProduct = () => {
  const query = graphql`
    query EnrolmentProduct {
      enrolmentProduct: contentfulProduct(
        slug: { eq: "ambassador-enrolment" }
      ) {
        ...ProductDetailsFragment
        shortDescription
        productType {
          name
          description
          fullDescription {
            id
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  `;

  return useStaticQuery<{enrolmentProduct: IProduct}>(query).enrolmentProduct;
};
