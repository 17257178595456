import { ThemeColor } from '../types/types';
import styled from 'styled-components';

export const Section = styled.section<SectionProps>`
  --background: ${p => (p.bg ? `var(--${p.bg})` : 'transparent')};
  --color: ${p => (p.color ? `var(--${p.color})` : 'var(--black)')};

  background: var(--background);
  color: var(--color);
  padding: ${p => (p.gutter ? 'var(--gutter)' : '0px')};
`;

export interface SectionProps {
  bg?: ThemeColor;
  color?: ThemeColor;
  gutter?: boolean;
}
