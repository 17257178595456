import styled from "styled-components";


export const StyledBadgeButton = styled.button<StyledBadgeButtonProps>`
  position: relative;
  background: none;
  cursor: pointer;
  border: none;

  &::after {
    content: "${({ badgeContent }) => badgeContent}";
    position: absolute;
    top: -4px;
    right: -2px;
    border-radius: 50%;

    background-color: orange;
    height: 16px;
    width: 16px;
    color: white;
    font: 500 12px / 1.333 var(--primaryFont);
    opacity: 0;
    transform: scale(0%);
    transition: opacity, transform 0.3s cubic-bezier(.22, 1, .36, 1);
  }

  &.show::after {
    opacity: 1;
    transform: scale(100%);
  }

  @media (max-width: 768px) {
    &::after {
      width: 14px;
      height: 14px;
      font-size: 10px;
    }
  }
`;


interface StyledBadgeButtonProps {
  badgeContent: number | string;
}