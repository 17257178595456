import { useCart } from '@chordcommerce/gatsby-theme-autonomy';
import { useLocation } from '@reach/router';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { CartSummary } from '~/components/CartSummary/CartSummary';
import { useLineItemCount } from '~/hooks/components/use-line-item-count';
import useUser from "~/hooks/components/use-user";
import { AMBASSADOR_ENROLLMENT } from "~/types/constants";
import { useRelatedProducts } from '../../hooks/graphql/queries/use-related-products';
import { Wrapper } from '../../styles/Wrapper.styled';
import { useCartContext } from '../CartContext/CartContext';
import { CartItem } from '../CartItem/CartItem';
import { Cta } from '../Generic/Cta/Cta';
import { UpsellCarousel } from '../UpsellCarousel/UpsellCarousel';
import { StyledMiniCart } from './MiniCart.style';
import { SharedCartItem } from '~/components/CartItem/SharedCartItem';
import { SharedCartSummary } from '~/components/Cart/SharedCartSummary/SharedCartSummary';
import { useElementSize } from '@mantine/hooks';

export const MiniCart: React.FC<MiniCartProps> = () => {
  const { cart, removeFromCart } = useCart();
  const { user } = useUser();
  const { isEnrolmentInCart, ambassadorProduct, isCartEmpty, closeCart, isSharedOrder, wishlistKits, wishlistProducts } = useCartContext();
  const lineItemCount = useLineItemCount();
  const wishlistCount = wishlistKits.length + wishlistProducts.length;
  const isAmbassadorUser = user?.data?.roles?.includes?.('ambassador');
  const relatedProducts = useRelatedProducts();
  const summaryEl = useElementSize();
  const sharedSummaryEl = useElementSize();
  const [isRemovingEnrolment, setIsRemovingEnrolment] = useState(false)
  const isWishlistEmpty = wishlistCount === 0;

  const filteredProducts = relatedProducts?.filter(p => {
    return !cart.data.lineItems?.find(
      lineItem => lineItem.variant.sku === p.sku
    );
  });

  useEffect(() => {
    const removeEnrolmentFromCart = async () => {
      setIsRemovingEnrolment(true)
      await removeFromCart(cart.data?.lineItems?.find(item => item.variant.slug === AMBASSADOR_ENROLLMENT.slug).id)
    }

    if (!isRemovingEnrolment && isEnrolmentInCart && isAmbassadorUser) {
      removeEnrolmentFromCart().catch(console.error).finally(() => setIsRemovingEnrolment(false))
    }
  }, [isEnrolmentInCart, isAmbassadorUser, cart]);

  

  const location = useLocation();
  const isAmbassadorPortal = false && location?.pathname?.includes?.(
    '/account/ambassador'
  );

  const cartItems = useMemo(() => {
    if (isSharedOrder) {
      return [...wishlistKits, ...wishlistProducts].map((item) => <SharedCartItem data={item} key={item.sku} />)
    }

    return cart.data?.lineItems?.map((lineItem) => <CartItem data={lineItem} key={lineItem.id} />);
  }, [isSharedOrder, cart.data?.lineItems, wishlistProducts, wishlistKits])

  return (
    <StyledMiniCart
      summaryHeight={summaryEl.height || sharedSummaryEl.height || 0}
      isSharedOrder={isSharedOrder}
    >
      <div className="cart-header padding">
        {!isSharedOrder && <h3>Cart ({lineItemCount})</h3>}
        {isSharedOrder && <h3>Wishlist ({wishlistCount})</h3>}
        {isAmbassadorPortal && (
          <div className="cart-header-message">
            <p>
              You are currently building an order for someone else. To make an
              order for yourself, do so through the <a href="/shop">shop</a>.
            </p>
          </div>
        )}
      </div>
      {isCartEmpty && !(isSharedOrder && wishlistCount > 0) && (
        <div className="empty-cart-cta padding">
          {isSharedOrder ? (
            <p>Your wishlist is empty.</p>
          ) : (
            <>
              <p>Your cart is empty. Let’s change that.</p>
              <Cta href="/shop" onClick={closeCart}>
                Continue Shopping
              </Cta>
            </>
          )}
        </div>
      )}
      <div className="scroll-area">
        <div className="cart-content padding">{cartItems}</div>
        <div className="carousel-wrapper">
          {!isEnrolmentInCart && !isCartEmpty && !isSharedOrder && (
            <UpsellCarousel products={filteredProducts} />
          )}
        </div>
        {isEnrolmentInCart && (
          <Wrapper className="amb-wrapper padding">
            <Wrapper bg="lime" rounded className="ambassador-notice">
              <p className="section-label">
                {ambassadorProduct.productType.description}
              </p>
              <div
                className="section-desc"
                dangerouslySetInnerHTML={{
                  __html:
                    ambassadorProduct.productType.fullDescription
                      .childMarkdownRemark.html,
                }}
              />
            </Wrapper>
          </Wrapper>
        )}
      </div>
      {isSharedOrder && !isWishlistEmpty && (
        <SharedCartSummary ref={sharedSummaryEl.ref} />
      )}
      {!isCartEmpty && !isSharedOrder && <CartSummary ref={summaryEl.ref} />}
    </StyledMiniCart>
  );
};

interface MiniCartProps {
}
