import styled from 'styled-components';
import { pxtorem } from '~/utils/tools';

export const StyledNewsletterSignup = styled.div`
  text-align: center;

  @media (min-width: 768px) {
    text-align: left;
  }

  input[type='email'] {
    border-radius: 0;
    border-color: var(--white);
    color: var(--white);
    font-size: ${pxtorem(16)};

    text-align: center;

    @media (min-width: 768px) {
      text-align: left;
    }

    ::placeholder {
      color: rgba(252, 250, 243, 0.3);
    }
  }

  button[type='submit'] {
    margin-top: 2rem;
  }
`;
