import api from '@chordcommerce/gatsby-theme-autonomy/src/services/api';
async function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export async function exigo(path: string, asJson: boolean = true): Promise<any> {
  // const url = typeof window === 'undefined' ? '/' : (window.location?.origin.includes('localhost') || window.location?.origin.includes('127.0.0.1') ? 'https://deploy-preview-31--commons-staging.netlify.app/' : '/');
  const url = `/`;
  const finalPath = `.netlify/functions/exigo/${path}`;
  const response = await fetch(`${url}${finalPath}`, {
    headers: {
      Authorization: await api.getAuthorizationHeader()
    }
  });
  // await sleep(3000); // Fake delay for demo purposes
  return asJson ? await response.json() : response;
}