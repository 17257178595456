import styled from "styled-components";

export const StyledNotificationBar = styled.div<StyledNotificationBarProps>`
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.5;
  padding-top: var(--shop-with-height, 0px);

  section {
    padding: 6px 0;

    @media (min-width: 900px) {
      padding: 12px 0;
    }

    ${({ extraPadding }) =>
      extraPadding &&
      `
      @media (min-width: 900px) {
        padding-bottom: 42px;
      }
  `}
  }

  p {
    margin: 0;
  }
`;

interface StyledNotificationBarProps {
  extraPadding?: boolean;
}
