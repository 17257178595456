import React, { useEffect, useMemo, useState } from "react";
import { CartConsumer } from "../CartContext/CartContext";
import { Modal } from "../Modal/Modal";
import { exigo } from "~/utils/exigo";
import { useCart } from "@chordcommerce/gatsby-theme-autonomy";
import { Cta } from "../Generic/Cta/Cta";

let timeout:any = null;
const Banner: React.FC<BannerProps> = ({ name, hide, onClick }) => {
  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      if (name && !hide) {
        document.body.classList.add('has-shop-with-banner');
      } else {
        document.body.classList.remove('has-shop-with-banner');
      }
    }, name && !hide ? 1500 : 0);
  }, [name, hide])
  return name && !hide ? (
    <div className="ShoppingWith">
      <div className="ShoppingWith__text"><span>Shopping with {name}</span><button className="ShoppingWith__action" onClick={onClick}>Change</button></div>
      <div className="ShoppingWith__corners" />
      <div className="ShoppingWith__corners ShoppingWith__corners--bottom" />
    </div>
  ) : null
};

const searchIcon = <svg  viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.932 12.4171L9.94806 9.43311C10.8049 8.44613 11.257 7.16974 11.2104 5.8605C11.1624 4.51153 10.5887 3.2349 9.61184 2.30336C8.63506 1.3719 7.33261 0.859297 5.98292 0.875367C4.63329 0.891359 3.34334 1.43466 2.38903 2.38906C1.43465 3.34353 0.891367 4.63349 0.875363 5.98297L1.00035 5.98445L0.875363 5.98297C0.859371 7.33261 1.37189 8.63505 2.30335 9.6119L2.30335 9.61191C3.2349 10.5887 4.51143 11.1624 5.86045 11.2105C7.16955 11.2571 8.44594 10.8049 9.43304 9.94812L12.5061 13.0212L12.5061 13.0213L12.5077 13.0228C12.6506 13.1607 12.8778 13.1589 13.0183 13.0183C13.1588 12.8778 13.1609 12.6506 13.0227 12.5077L12.9336 12.4155L12.932 12.4171ZM1.64298 6.06417C1.64301 4.89166 2.10884 3.76726 2.93789 2.93811C3.76706 2.10902 4.89156 1.64318 6.06403 1.64318C7.23651 1.64318 8.36121 2.10903 9.19015 2.9381L9.27855 2.84972L9.19016 2.9381C10.0192 3.76728 10.4851 4.89178 10.4851 6.06426C10.4851 7.23675 10.0192 8.36146 9.19017 9.19041C8.36111 10.0193 7.23659 10.4853 6.06417 10.4853C4.892 10.484 3.76817 10.0178 2.9394 9.18892C2.11061 8.36005 1.64437 7.23628 1.64298 6.06417Z" fill="currentColor" stroke="currentColor" stroke-width="0.25"/>
</svg>

const closeIcon = <svg viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<line x1="0.353553" y1="0.646447" x2="18.3536" y2="18.6464" stroke="currentColor"/>
<line x1="0.646447" y1="18.6464" x2="18.6464" y2="0.646446" stroke="currentColor"/>
</svg>



const ShopWithBanner = ({hide} : {hide?: boolean}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [value, setValue] = useState('');
  const [allAmbassadors, setAllAmbassadors] = useState([]);
  const matchingResults = useMemo(() => {
    if (!value) {
      return [];
    }
    return allAmbassadors.filter((ambassador: any) => ambassador.name.toLowerCase().includes(value.toLowerCase())).slice(0, 8);
  }, [value, allAmbassadors])
  const [selectedAmbassador, setSelectedAmbassador] = useState(null);
  const [hasLoadedAmbassadors, setHasLoadedAmbassadors] = useState(false);
  useEffect(() => {
    if (!modalOpen || hasLoadedAmbassadors) {
      return
    }
    setHasLoadedAmbassadors(true);
    (async () => {
      setAllAmbassadors(await exigo('ambassadors'));
    })();
  }, [modalOpen, hasLoadedAmbassadors])

  const {modifyCart} = useCart();

  return (
    <CartConsumer>
        {({ shopWithName, forceAmbassadorInfo, setShopWithId }) => (<>
          <Banner name={shopWithName} hide={!!hide} onClick={() => setModalOpen(true)} />
          <Modal
            isOpen={modalOpen}
            onClose={() => setModalOpen(false)}
            styleProps={{
              bg: 'white',
              maxWidth: 480,
              hideCloseButton: true,
              fullScreenMobile: true,
            }}
          >
            <div className="ShopWithChange">
              <h3 className="ShopWithChange__title">Change Ambassador</h3>
              <button className="ShopWithChange__close" onClick={() => setModalOpen(false)}>{closeIcon}<span className="hidden-sr">Close</span></button>
              {selectedAmbassador ? (
                <div className="ShopWithChange__selected">
                  <h4 className="ShopWithChange__selectedTitle">You will now begin shopping with {selectedAmbassador.name}.</h4>
                  <Cta
                    variant="primary"
                    onClick={async () => {
                      window.localStorage.setItem('ambassadorId', selectedAmbassador.id);
                      modifyCart({
                        metadata: {
                          ambassador_id: selectedAmbassador.id,
                          ambassadorId: selectedAmbassador.id, // Chord expects both :(
                        },
                      });
                      setShopWithId(selectedAmbassador.id);
                      setModalOpen(false);
                      setTimeout(() => setSelectedAmbassador(null), 500);
                    }}>
                      Confirm selection
                    </Cta>
                    <Cta onClick={() => setSelectedAmbassador(null)}>
                      Change selection
                    </Cta>
                </div>) : (
                <div className="ShopWithChange__form">
                  <div className={`ShopWithChange__input ${value ? 'has-value' : ''}`}>
                    <input type="text" autoComplete="off" placeholder="Search for an ambassador by typing their name..." value={value} onChange={(e) => setValue(e.target.value)} />
                    {searchIcon}
                  </div>
                  {value ? 
                  <div className="ShopWithChange__results">
                    <div className="ShopWithChange__resultsCount">{!matchingResults.length ? 'No results' : `Showing ${matchingResults.length} result${matchingResults.length > 1 ? 's' : ''}`}</div>
                    <div className="ShopWithChange__resultsList">
                      {matchingResults.map((ambassador: any) => (
                        <button className="ShopWithChange__result" key={ambassador.id} onClick={() => setSelectedAmbassador(ambassador)}>{ambassador.name}</button>
                      ))}
                    </div>
                  </div> : null}

                </div>
              )}
            </div>
          </Modal>
        </>)}
    </CartConsumer>
  );
}

interface BannerProps {
  name: string;
  hide: boolean;
  onClick?: () => void;
}

export default ShopWithBanner;
