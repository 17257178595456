import React, { useEffect, useRef } from 'react';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import gsap from 'gsap';

import { useMediaQuery, useWindowScroll } from '@mantine/hooks';

import { Section } from '../../styles/Section.styled';
import { DesktopNav } from './DesktopNav/DesktopNav';
import { MobileNav } from './MobileNav/MobileNav';
import { StyledHeader } from './Header.style';
import useNavigationQuery from '~/hooks/graphql/queries/use-navigation';
import { useLocation } from '@reach/router';

const quicklinks = [
  { slug: '/account', name: 'Profile' },
  { slug: '/cart', name: 'Cart' },
];

export const Header: React.FC<HeaderProps> = ({ floating }) => {
  const headerHeight = 63;

  const headerRef = useRef();
  const mqMatches = useMediaQuery('(min-width: 900px)', true);
  const [scroll] = useWindowScroll();
  const { main, shopSubmenu } = useNavigationQuery();
  const location = useLocation();
  const isFixed = scroll.y > headerHeight + 40;

  useEffect(() => {
    if (isFixed) {
      if (!floating) {
        gsap.set(document.body, { paddingTop: headerHeight });
      }
      gsap.from(headerRef.current, {
        yPercent: -100,
        clearProps: 'transform',
      });
    } else {
      gsap.set(document.body, { paddingTop: 0 });
    }
  }, [isFixed]);
  const isAmbassadorPortal =
    location?.pathname?.includes?.('account/ambassador');
  const pages = isAmbassadorPortal
    ? [
        {
          slug: 'account/ambassador',
          name: 'Dashboard',
        },
        {
          slug: 'account/ambassador/performance',
          name: 'Performance',
        },
        {
          slug: 'account/ambassador/orders',
          name: 'Orders',
        },
        {
          slug: 'account/ambassador/news',
          name: 'News & Education',
        },
      ]
    : main?.pages;
  return (
    <StyledHeader ref={headerRef} floating={floating} fixed={isFixed}>
      <Section gutter={!isFixed && mqMatches} bg={isFixed ? 'white' : null}>
        {mqMatches ? (
          <DesktopNav
            pages={pages}
            shopSubmenu={shopSubmenu}
            quicklinks={quicklinks}
            isFixed={isFixed}
          />
        ) : typeof window === 'undefined' ? null : (
          <MobileNav
            pages={isAmbassadorPortal ? [...pages, {type: 'separator'}, ...main.pages] : main.pages}
            shopSubmenu={shopSubmenu}
            quicklinks={quicklinks}
          />
        )}
      </Section>
    </StyledHeader>
  );
};

interface HeaderProps {
  floating?: boolean;
}

export interface PageItem {
  slug?: string;
  name?: string;
  type?: 'separator' | 'header' | 'link';
}

export interface SubMenu {
  columns: {
    title: string;
    items: {
      slug: string;
      name: string;
      productType?: string;
      url?: string;
      __typename?: string;
    }[];
  }[];
  image: {
    title: string;
    gatsbyImageData: IGatsbyImageData;
  };
}
