import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  fonts: {
    primary: 'Founders Grotesk, sans-serif',
    secondary: 'Berlingske Serif, serif',
    fontSize: 16,
  },
  colors: {
    black: '#131313',
    blackHover: '#616161',
    white: '#FFFFFF',
    green: '#34563B',
    red: '#EB614E',
    coral: '#ea7359',
    orange: '#FFAD00',
    orangeDark: '#FF9100',
    gray: '#F6F3F0',
    darkGray: '#393D2A',
    darkSand: '#D9D4CB',
    lime: '#E7F7D3',
    offWhite: '#FCFAF3',
  },
};

export default theme;
