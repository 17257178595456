import { useState, useEffect } from 'react';
import {
  useAuth,
  useUser as chordUseUser,
} from '@chordcommerce/gatsby-theme-autonomy';

if (typeof window !== 'undefined') {
  (window as any).Chord = (window as any).Chord || {};
}

let globalActiveUser: ChordUser = null;

export const useUser = () => {
  const { isLoggedIn } = useAuth();
  const [activeUser, setActiveUser] = useState(globalActiveUser || null);
  const { user } = chordUseUser();

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    if (user?.data?.id && user?.data?.createdAt) {
      globalActiveUser = JSON.parse(JSON.stringify(user));
      (window as any).Chord = (window as any).Chord || {};
      (window as any).Chord.currentUser = globalActiveUser;
      setActiveUser(globalActiveUser);
    }
    if (isLoggedIn && user?.data?.createdAt) {
      try {
        window.localStorage.setItem('chord-user', JSON.stringify(user));
      } catch (e) {}
    } else if (!isLoggedIn) {
      try {
        window.localStorage.removeItem('chord-user');
      } catch (e) {}
    }
  }, [isLoggedIn, user]);

  return {
    user: activeUser,
  };
};

export default useUser;
