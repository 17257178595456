import { useState, useEffect } from 'react';
import { utils } from '@chordcommerce/gatsby-theme-autonomy';
import useNotificationBarQuery from '~/hooks/graphql/queries/use-notification-bar';
import { useUser } from '@chordcommerce/gatsby-theme-autonomy';

const { getParam } = utils;

const useNotificationBar = () => {
  const { user } = useUser();
  const [showRefNotification, setShowRefNotification] = useState(false);
  const [giftCardNotification, setGiftCardNotification] =
    useState<GiftCardNotif>(null);
  const [loaded, setLoaded] = useState(false);
  const availableGiftCards = user?.data?.availableGiftCards;

  useEffect(() => {
    setLoaded(true);
    setShowRefNotification(!!getParam('ref'));
  }, [setLoaded]);

  const { notificationBar, referralNotification } = useNotificationBarQuery();

  useEffect(() => {
    if (!availableGiftCards?.length) {
      setGiftCardNotification(null);
      return;
    }
    const giftCardAmount = availableGiftCards.reduce(
      (total: any, giftCard: any) => total + giftCard.amount,
      0
    );
    setGiftCardNotification({
      description: `Hooray, you have a $${giftCardAmount} gift remaining! Shop now`,
      href: '/shop',
    });
  }, [availableGiftCards]);

  const notification = showRefNotification
    ? referralNotification?.notification
    : giftCardNotification ?? notificationBar?.notification;

  return { loaded, notification };
};

export default useNotificationBar;

interface GiftCardNotif {
  href: string;
  description: string;
}
