import styled from 'styled-components';
import { pxtorem, desktopHover } from '~/utils/tools';

export const StyledDesktopNav = styled.div<StyledDesktopNavProps>`
  .desktopnav__wrapper {
    position: relative;
    z-index: 10;
    background: var(--white);
    overflow: visible;
    ${p => p.isFixed && 'border-radius: 0px;'}
  }

  .desktopnav__navs {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .desktopnav__nav {
    display: flex;

    @media (min-width: 768px) {
      padding: 14px 35px;
    }

    .logo svg {
      height: 14px;
      width: auto;
      margin-top: 5px;
    }

    & > ul {
      list-style: none;
      display: flex;

      > li {
        &:not(:last-child) {
          margin-right: 2rem;
        }

        > a {
          position: relative;

          color: var(--black);
          font-weight: 500;
          text-decoration: none;

          transition: color 250ms;

          ${desktopHover(
            `
          color: var(--green);
        `,
            true
          )}
        }
      }
    }

    &--left {
      a {
        display: flex;
        align-items: center;
      }
    }

    // Hover animation on main menu
    &--center {
      > ul {
        > li {
          &:hover > a,
          > a.active {
            &:before {
              content: '';
            }

            &:after {
              transform-origin: left center;
              transform: scaleX(1);
            }
          }

          > a {
            &:before {
              content: none;
              position: absolute;
              left: -20px;
              right: -20px;
              top: -10px;
              height: 60px;
            }

            &:after {
              transition: transform 0.25s var(--cubic-ease);
              content: '';
              position: absolute;
              bottom: -5px;
              left: 0;
              right: 0;
              height: 2px;
              background: currentColor;
              transform: scaleX(0);
              transform-origin: right center;
            }
          }
        }
      }
    }

    &--right {
      ul li {
        &:not(:last-child) {
          margin-right: 3rem;
        }
        a:hover {
          fill: var(--green);
        }

        svg {
          height: 1.3em;
          width: auto;
          margin-top: 2px;
        }
      }
    }
  }

  .submenu__backdrop {
    visibility: hidden;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: rgba(31, 31, 31, 0.2);
    opacity: 0;
    transition: opacity 250ms, visibility 0ms 250ms;
    z-index: 2;
  }

  &.submenu--opened {
    .submenu__backdrop {
      visibility: visible;
      opacity: 1;
      transition-delay: 0ms;
    }
  }
`;

interface StyledDesktopNavProps {
  isFixed?: boolean;
}
