import { ReferenceType } from '@floating-ui/react-dom';
import GatsbyLink from 'gatsby-link';
import React, { forwardRef, Ref } from 'react';

import { scrollToSelector } from '~/utils/tools';
import { useCartContext } from '../../CartContext/CartContext';
import { StyledA, StyledLink, StyledBtn } from './Cta.style';

type RefTypes = HTMLAnchorElement | HTMLButtonElement | GatsbyLink<any> | ReferenceType;

export const Cta = forwardRef<RefTypes, CtaProps>(({
  children,
  className,
  href = '',
  variant,
  size,
  invert,
  leftIcon,
  rightIcon,
  forceLink,
  onClick,
  onError,
  type = "button",
  disabled,
  ...props
}, ref) => {
  const { enrollAmbassador } = useCartContext();
  const enrollOnClick = href === '#enroll-ambassador';
  const classes = `${variant ? `cta--${variant}` : ''}${
    size ? ` cta--${size}` : ''
  }${invert ? ' cta--invert' : ''} ${`${className}` || ''}`;

  const handleButtonClick = (e: React.MouseEvent): void => {
    try {
      onClick && onClick(e);
    } catch (err) {
      onError && onError(err);
    }
  };

  const handleEnrolment = async (e: React.MouseEvent) => {
    e.preventDefault();
    await enrollAmbassador()
  }

  const inner = (
    <div className="cta__inner">
      {leftIcon && (
        <span className="cta__icon cta__icon--left">{leftIcon}</span>
      )}
      <span className="cta__label">{children}</span>
      {rightIcon && (
        <span className="cta__icon cta__icon--right">{rightIcon}</span>
      )}
    </div>
  );

  if (
    href.startsWith('http') ||
    href.startsWith('mailto:') ||
    href.startsWith('tel:') || 
    forceLink
  ) {
    return (
      <StyledA
        className={classes}
        target={forceLink ? undefined : "_blank"}
        href={href}
        onClick={handleButtonClick}
        ref={ref as Ref<HTMLAnchorElement>}
        {...props}
      >
        {inner}
      </StyledA>
    );
  } else if (href === '#' || !href) {
    return (
      <StyledBtn
        className={classes}
        type={type}
        onClick={handleButtonClick}
        disabled={disabled}
        ref={ref as Ref<HTMLButtonElement>}
        {...props}
      >
        {inner}
      </StyledBtn>
    );
  } else if (href.startsWith('#')) {
    return (
      <StyledBtn
        className={classes}
        type="button"
        disabled={disabled}
        onClick={enrollOnClick ? handleEnrolment : scrollToSelector.bind(null, href)}
        ref={ref as Ref<HTMLButtonElement>}
        {...props}
      >
        {inner}
      </StyledBtn>
    );
  } else {
    return (
      <StyledLink
        className={classes}
        to={href}
        onClick={handleButtonClick}
        ref={ref as Ref<GatsbyLink<any>>}
        {...props}
      >
        {inner}
      </StyledLink>
    );
  }
});

export interface CtaProps {
  children: React.ReactNode;
  href?: string;
  className?: string;
  variant?: 'primary' | 'secondary';
  size?: 'small' | 'large';
  invert?: boolean;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  onClick?: (e: React.MouseEvent) => void;
  onError?: (err: unknown) => void;
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
  disabled?: boolean;
  forceLink?:boolean;
}
