import styled from "styled-components";
import { pxtorem } from "../../../utils/tools";

export const StyledQuantitySelector = styled.div`
  display: flex;
  align-items: center;
  gap: ${pxtorem(12)};

  .qty-btn {
    width: 27px;
    height: 27px;
    border-radius: 2px;
    border: none;
    background-color: #F6F3F0;
    display: flex;
    align-items: center;
    justify-content: center;

    line-height: 100%;
    padding: 0;
    cursor: pointer;
    transition: background-color 0.1s var(--cubic-ease);
    
    &:hover:not(:disabled) {
      background-color: var(--lime);
    }
  }

`;