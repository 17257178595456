import React from 'react';
import { BadgeButton } from '~/components/BadgeButton/BadgeButton';
import { useLineItemCount } from '~/hooks/components/use-line-item-count';
import ShoppingBagIcon from '../../../assets/images/icons/shoppingbag.svg';
import HeartIcon from '../../../assets/images/icons/heart.svg';
import { useCartContext } from '~/components/CartContext/CartContext';

export const CartBadgeButton: React.FC<CartBadgeButtonProps> = ({name}) => {
  const lineItemCount = useLineItemCount();
  const { isSharedOrder, wishlistKits, wishlistProducts } = useCartContext();
  const sharedOrderCount = wishlistKits.length + wishlistProducts.length;
  return (
    <BadgeButton badgeContent={isSharedOrder ? sharedOrderCount : lineItemCount}>
      {isSharedOrder ? <HeartIcon title={name} /> : <ShoppingBagIcon title={name} />}
    </BadgeButton>
  );
};

interface CartBadgeButtonProps {
  name: string;
}
