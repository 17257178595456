import styled from 'styled-components';
import { pxtorem } from '~/utils/tools';
import { PanelOrigin } from '~/types/types';

const offsetPercent = 140;
export const StyledPanel = styled.div<StyledSidePanelProps>`
  /* --controls-height: ${pxtorem(40)}; */
  --x-padding: ${pxtorem(50)};

  position: fixed;
  
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100%;
  z-index: 40;
  pointer-events: ${({isOpen}) => isOpen ? "all" : "none"};
  
  .close-btn {
    border: none;
    background: none;
    position: relative;

    cursor: pointer;
    z-index: 40;
    color: var(--black);
    svg {
      width: ${pxtorem(24)};
      height: ${pxtorem(24)};
      color: inherit;
      transition: transform 0.3s var(--cubic-ease);
    }
  }

  .btn-space {
    display: block;
    background-color: inherit;
    padding-inline: ${pxtorem(50)};
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: ${pxtorem(48)};

    @media (max-width: 768px) {
      padding-top: ${pxtorem(24)};
      padding-inline: var(--x-padding);
      position: absolute;
      right:0;
    }
  }
  
  .panel-bg {
    background-color: #1f1f1f;
    
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: -40;
    opacity: 0;
    transition: opacity 0.3s var(--cubic-ease);
    
    &.open {
      opacity: 0.2;
      z-index: 40;
    }
    
    @media (max-width: 768px) {
      display: none;
    }
  }
  
  .panel {
    height: 100%;
    width: max(37.5vw, 540px);
    background-color: var(--white);
    position: absolute;
    right: ${({ panelOrigin }) => (panelOrigin === 'right' ? 0 : 'unset')};
    left: ${({ panelOrigin }) => (panelOrigin === 'left' ? 0 : 'unset')};
    top: ${({ panelOrigin }) => (panelOrigin === 'top' ? 0 : 'unset')};
    bottom: ${({ panelOrigin }) => (panelOrigin === 'bottom' ? 0 : 'unset')};
    transform-origin: ${({panelOrigin}) => panelOrigin};
    transition: transform 0.5s var(--cubic-ease);
    z-index: 40;
    
    transform: ${({ panelOrigin }) => {
      switch (panelOrigin) {
        case 'bottom':
          return `translateY(${offsetPercent}%)`;
        case 'left':
          return `translateX(-${offsetPercent}%)`;
        case 'right':
          return `translateX(${offsetPercent}%)`;
        case 'top':
          return `translateY(-${offsetPercent}%)`;
      }
    }};

    &.open {
      transform: ${({ panelOrigin }) => {
        switch (panelOrigin) {
          case 'bottom':
            return `translateY(0%)`;
          case 'left':
            return `translateX(0%)`;
          case 'right':
            return `translateX(0%)`;
          case 'top':
            return `translateY(0%)`;
        }
      }};
    }

    @media (max-width: 768px) {
      --clearance: ${p => p.top}px;
      width: 100%;
      top: var(--clearance);
      height: calc(100vh - var(--clearance)); 
      --x-padding: min(3.5%, ${pxtorem(20)});
    }
  }

  @media (max-width: 768px) {
    --x-padding: ${pxtorem(24)};
  }
`;

interface StyledSidePanelProps {
  panelOrigin: PanelOrigin;
  isOpen: boolean;
  top: number;
}
