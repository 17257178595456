import styled from "styled-components";
import { pxtorem } from "../../utils/tools";
import { StyledPLPModal } from "../ProductListPage/PLP.style";

export const StyledAmbassadorModal = styled(StyledPLPModal)`
  --y-padding: ${pxtorem(10)};
  --x-padding: ${pxtorem(40)};

  padding: var(--y-padding) var(--x-padding);

  .cta--secondary {
    font-weight: 500;
    font-size: ${pxtorem(20)};
  }

  @media (max-width: 1024px) {
    --y-padding: 0px;
    --x-padding: 0px;
  }
`;