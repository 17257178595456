import styled from 'styled-components';

export const StyledSharedCartSummary = styled.div`
  &.sharedOrderSummary,
  .sharedOrderSummary {
    padding: 32px 48px;
    margin-inline: auto;
    position: fixed;
    z-index: 4;
    bottom: 0;
    width: 100%;
    background-color: var(--gray);

    &__cta {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 24px;

      &__label {
        font: 500 1rem/1.175 var(--primaryFont);
      }

      &Button {
        font-weight: 400;

        &:after {
          height: 1px;
          bottom: -1px;
        }
      }
    }

    &__link {
      border-radius: 42px;
      background-color: var(--white);
      display: flex;
      align-items: center;
      overflow: hidden;
      min-height: 56px;
      padding-right: 16px;
      position: relative;
      .cta--primary {
        min-width: 56px;
        min-height: 56px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;

        .cta__inner {
          width: 100%;
          height: 100%;
          padding: 0;
        }
      }

      &__text {
        margin-left: 16px;
        overflow: hidden;

        white-space: nowrap;
        text-overflow: ellipsis;
      }

      @media (max-width: 768px) {
        justify-content: center;

        .cta--primary {
          display: none;
        }
      }
    }

    &__info {
      position: absolute;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 42px;
      color: var(--white);
      font: 500 1rem/1.175 var(--primaryFont);
      text-align: center;
      background-color: var(--green);
      left: -100%;
      
      &, &__label {
        transition: 0.55s var(--cubic-ease);
        transition-property: transform, opacity;
      }

      &__label {
        opacity: 0;
        transition-delay: 0.55s;
        transform: translateY(50%);
      }

      &--active {
        transform: translateX(100%);

        .sharedOrderSummary__info__label {
          transform: translateY(0%);
          opacity: 1;
        }        
      }
    }
  }
`;
