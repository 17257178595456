import styled from 'styled-components';
import { pxtorem } from '../../utils/tools';

export const StyledCartSummary = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-left: var(--x-padding);
  padding-right: var(--x-padding);
  background-color: var(--white);
  z-index: 10;
  &:before {
    content: '';
    position: absolute;
    top: -16px;
    left: 0;
    right: 0;
    height: 16px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
  }
  @media (max-width: 768px) {
    position: sticky;
  }

  p {
    margin: 0;
  }

  hr {
    margin: 0;
    color: #000;
    opacity: 0.1;
  }

  .flex-between {
    display: flex;
    justify-content: space-between;
  }

  .summary {
    padding: ${pxtorem(12)} 0;

    .flex-between:first-child {
      margin-bottom: ${pxtorem(12)};
    }
  }

  .total {
    padding: ${pxtorem(18)} 0;
    p {
      font: 400 ${pxtorem(20)} / 117% var(--primaryFont);
    }
  }

  .cta--primary,
  .cta--large {
    width: 100%;
  }

  .cta--large {
    padding-top: ${pxtorem(16)};
    padding-bottom: ${pxtorem(16)};

    &:disabled, &:disabled &:hover {
      border-color: var(--gray);
      color: var(--gray);
      cursor: not-allowed;
      background-color: transparent;
    }

    @media (max-width: 768px){
      display: flex;
      justify-content: center;
      align-items: center;
      max-height: ${pxtorem(50)};
    }
  }

  .cart-ambassador-copy {
    padding: ${pxtorem(12)} 0;
    &-alert {
      color: var(--white);
      font-weight: 500;
      background: var(--orange);
      border-radius: 10px;
      padding: 1rem;
      margin: 0 -10px 10px -10px;
    }
    label {
      font-size: ${pxtorem(18)};
      font-weight: 400;
      input {
        border: none;
        padding: 0.5rem 0;
        font-size: ${pxtorem(14)};
        font-weight: 300;
      }
    }
    &-actions {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: ${pxtorem(12)};
      @media (max-width: 768px) {
        grid-template-columns: 1fr;
      }
      > * {
        width: auto;
      }
    }
  }

  form {
    .tax-info {
      margin-top: ${pxtorem(16)};
      margin-bottom: ${pxtorem(16)};
      text-align: center;
      margin-inline: auto;
      @media (max-width: 768px) {
        margin-top: ${pxtorem(8)};
        margin-bottom: ${pxtorem(8)};
        font-size: ${pxtorem(15)};
      }
    }
    
    .cta--secondary:after {
      height: 1px;
      bottom: 0px;
    }
    a {
      width: auto;
      margin: 0;


      span {
        font-weight: 400;
        margin: 0;
      }
    }
  }
`;
