import styled from "styled-components";
import { pxtorem } from "../../utils/tools";

export const StyledQAProductCard = styled.article`
  background-color: var(--gray);
  height: 100%;
  width: 100%;
  border-radius: 10px;
  padding: ${pxtorem(10)} ${pxtorem(18)};
  box-sizing: border-box;

  aspect-ratio: 214 / 261;
    
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  a {
    color: var(--black);
    text-decoration: none;

    text-align: center;
    margin-bottom: 4px;

    &:hover {
      text-decoration: underline;
    }

    @media (max-width: 768px) {
      font-size: 12px;
    }
  }

  .gatsby-image-wrapper {
    position: relative;
    aspect-ratio: 1/1;
    overflow: hidden;
    margin-inline: auto;
    text-align: center;
    width: 70%;
  }

  img {
    position: absolute;
    width: 100%;
    transform: translateX(-50%);
  }


  .flex-row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 768px) {
      span {
        font-size: 12px
      }
    }
  }

  button {
    border-radius: 50%;
    width: 34px;
    height: 34px;
    border: 1px solid var(--black);
    cursor: pointer;
    padding: 0;
    color: var(--black);
    background-color: transparent;
    transition: background-color 0.1s var(--cubic-ease);
    &:hover:not(:disabled) {
      background-color: black;
      color: white;
    }

    &:disabled {
      cursor: auto;
      border-color: var(--darkSand);
      color: var(--darkSand);
    }
  }

  @media (max-width: 768px) {
    aspect-ratio: 158 / 190;
    padding: ${pxtorem(12)} ${pxtorem(18)};
  }
`;