import { createGlobalStyle } from 'styled-components';
import { desktopHover, pxtorem } from '~/utils/tools';

const GlobalStyles = createGlobalStyle`
  :root {
    --gutter: 22px;
    --radius: 10px;
    --primaryFont: ${p => p.theme.fonts.primary};
    --secondaryFont: ${p => p.theme.fonts.secondary};
    --background: ${p => p.theme.colors.white};
    --text: ${p => p.theme.colors.black};
    --white: ${p => p.theme.colors.white};
    --black: ${p => p.theme.colors.black};
    --blackHover: ${p => p.theme.colors.blackHover};
    --green: ${p => p.theme.colors.green};
    --red: ${p => p.theme.colors.red};
    --coral: ${p => p.theme.colors.coral};
    --orange: ${p => p.theme.colors.orange};
    --orangeDark: ${p => p.theme.colors.orangeDark};
    --darkGray: ${p => p.theme.colors.darkGray};
    --darkSand: ${p => p.theme.colors.darkSand};
    --gray: ${p => p.theme.colors.gray};
    --lime: ${p => p.theme.colors.lime};
    --offWhite: ${p => p.theme.colors.offWhite};

    --cubic-ease: cubic-bezier(.55,0,.1,1);

    @media (max-width: 768px) {
      --gutter: 16px;
      --radius: 12px;
    }
  }

  *, *:before, &:after {
    box-sizing: inherit;
  }

  html {
    background: var(--background);
    color: var(--text);
    font-size: ${p => p.theme.fonts.fontSize}px;
    font-weight: 300;
    font-display: fallback;
    font-family: var(--primaryFont);
    box-sizing: border-box;
    text-rendering: geometricPrecision;
  }
  
  body {
    background: var(--background);
    height: 100vh;
    color: var(--color);
    margin: 0;
    font-size: 1rem;
    line-height: 1.29;
    overflow-x: hidden;
    &.menu-opened, &.hide-scroll {
      overflow: hidden;
    }
  }

  body.disable-animations {
    *,*:before, *:after {
      transition: none !important;
    }
  }

  h1 {
    font-weight: 300;
    font-size: ${pxtorem(90)};
    line-height: 0.88;
    em {
      font-size: ${pxtorem(76)};
    }

    @media (max-width: 768px) {
     font-size: ${pxtorem(60)};
      em {
        font-size: ${pxtorem(52)};
      }
    }
  }

  h2 {
    font-size: ${pxtorem(60)};
    font-weight: 400;
    line-height: 1.1;
    em {
      font-size: ${pxtorem(52)};
    }
    
    @media (max-width: 768px) {
     font-size: ${pxtorem(40)};
      em {
        font-size: ${pxtorem(34)};
      }
    }
  }

  h3 {
    font-size: ${pxtorem(40)};
    line-height: 1.0;
    @media (max-width: 768px) {
     font-size: ${pxtorem(28)};
    }
  }

  h4 {
    font-size: ${pxtorem(24)};
    line-height: 1.0;
  }

  h1, h2, h3 {
    font-weight: 300;
  }

  h4, h5, h6 {
    font-weight: 400;
  }
  
  h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
  }

  em {
    font-family: var(--secondaryFont);
    font-style: italic;
    font-size: 0.9em;
  }

  input, select, button {
    font: inherit;
    font-weight: inherit;
    font-family: var(--primaryFont);
  }

  p a {
    color: var(--black);
    font-weight: 400;

    transition-property: color;
    transition-duration: 250ms;

    ${desktopHover(`
      color: var(--blackHover);
    `)}
  }
  
  ul,ol {
    margin: 0;
    padding: 0;
  }
  
  .hidden-sr {
    position: absolute;
    left: -9999px;
    width: 0;
    height: 0;
    overflow: hidden;
  }
  
  svg[class^='icon'] {
    vertical-align: middle;
    display: inline-block;
    width: 1em;
    height: 1em;
  }

  input,
  select {
    padding: 0.5rem;
    margin-top: 0.25rem;
  }

  input,
  select,
  button[type="submit"] {
    margin-top: 0.25rem;
  }

  input[type='text'], input[type='password'], input[type='email'], input[type='number'], input[type='tel'], input[type='url'], input[type='search'], input[type='date'], input[type='month'], input[type='week'], input[type='time'], input[type='datetime'], input[type='datetime-local'], input[type='color'], textarea {
    width: 100%;
    background: none;
    border: none;
    border-bottom: 1px solid var(--text);
    color: var(--text);
    ::placeholder {
      color: #393D2A;
    }
  }

  .mainContent {
    position: relative;
    z-index: 1;
    overflow: hidden;
  }
  body {
    --shop-with-border-size: 0px;
    --shop-with-height: 0px;
    &.has-shop-with-banner {
      --shop-with-border-size: 12px;
      --shop-with-height: 48px;
      @media (max-width: 768px) {
        --shop-with-border-size: 0px;
      }
    }
  }

  .ShopWithChange {
    &__selected {
      margin-top: 42px;
      display: flex;
      flex-direction: column;
      button {
        min-width: 222px;
        margin: 14px auto 0 auto;
      }
    }
    &__selectedTitle {
      font-family: var(--primaryFont);
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 112%;
      text-align: center;
      color: #131313;
      margin: 0 auto 30px auto;
    }
    &__title {
      font-family: var(--primaryFont);
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      text-transform: uppercase;
      color: #1F1F1F;
      text-align:center;
    }
    &__close {
      cursor: pointer;
      &:hover svg {
        transform: scale(1.05);
      }
      position: absolute;
      top: 40px;
      right: 40px;
      padding: 0;
      margin: 0;
      border: none;
      background: none;
      svg {
        width: 19px;
        height: 19px;
        transition: transform 0.3s ease-in-out;
      }
    }
    &__results {
      @media (max-width: 768px) {
        border: 1px solid #D9D9D9;
        border-radius: 12px;
        position: relative;
        padding: 12px 24px;
        margin-top: 16px;
      }
    }
    &__resultsList {
      margin: 0;
      padding: 0;
      position: relative;
    }
    &__result {
      display: block;
      padding: 8px 12px;
      background: transparent;
      border: none;
      cursor: pointer;
      width: 100%;
      text-align: left;
      border-radius: 12px;
      transition: background 0.3s ease-in-out;
      &:hover {
        background: #E7F7D3;
      }
      @media (max-width: 768px) {
        padding: 8px 0;
      }
    }
    &__resultsCount {
      font-family: var(--primaryFont);
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #7D7D7D;
      margin: 24px 0 12px 0;
      padding: 0 0 12px 0;
      &:after {
        position: absolute;
        width:100%;
        left:0;
        height: 1px;
        content: "";
        display: block;
        background: #D9D9D9;
        margin-top: 12px;
      }
      @media (max-width: 768px) {
        padding: 0;
        margin: 0;
        margin-bottom: 28px;
      }
    }
    &__form {
      margin-top: 48px;
    }
    &__input {
      position: relative;
      input {
        padding: 12px 16px 12px 40px;
        &::placeholder {
          color: #13131346;
          transition: color 0.3s ease-in-out;
        }
        border: 1px solid #D9D9D9;
        transition: border 0.3s ease-in-out;
        border-radius: 99px;
        &:focus {
          border: 1px solid #131313;
          outline: none;
        }
      }
      svg {
        position: absolute;
        top: 50%;
        left: 16px;
        width: 12px;
        height: 12px;
        margin-top: 2px;
        transform: translateY(-50%);
        transition: width 0.3s ease-in-out, height 0.3s ease-in-out;
      }
      &.has-value svg, input:focus + svg {
        width: 12px;
        height: 12px;
      }
    }
  }

  .ShoppingWith {
    position: fixed;
    top:0;
    left: 0;
    right:0;
    background: var(--coral);
    z-index: 39;
    animation: shopWithBackground 3s ease-in-out infinite alternate;
    padding: var(--shop-with-border-size);
    text-align: center;
    color: var(--white);
    transition: opacity 0.3s ease-in-out;
    font-family: var(--primary);
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.2;
    opacity: 0;

    .has-shop-with-banner & {
      opacity: 1;
    }

    @media (max-width: 768px) {
      padding: 8px 12px 8px 20px;
    }

    &:after {
      padding-bottom: 40px;
      animation: shopWithOutline 3s ease-in-out infinite alternate;
      position: fixed;
      top: calc(var(--shop-with-border-size) * 2 + 24px);
      left: var(--shop-with-border-size);
      right: var(--shop-with-border-size);
      bottom: var(--shop-with-border-size);
      content: '';
      pointer-events: none;
      border-radius:10px;
      outline: var(--shop-with-border-size) solid var(--coral);
      @media (max-width: 768px) {
        display: none;
      }
     }

     &__corners {
      @media (max-width: 768px) {
        display: none;
      }
      &:before, &:after {
        content: '';
        position: absolute;
        width: var(--shop-with-border-size);
        height: var(--shop-with-border-size);
        background: var(--coral);
        animation: shopWithBackground 3s ease-in-out infinite alternate;
        bottom: calc(var(--shop-with-border-size) * -1)
      }
      &:before {
        left: 0;
      }
      &:after {
        right: 0;
      }
      &--bottom {
        position: fixed;
        bottom:0;
        left: 0;
        right:0;
        &::after, &:before {
          bottom: 0;
          height: calc(var(--shop-with-border-size) * 1.4);
          width: calc(var(--shop-with-border-size) * 1.4);
        }
        &:after {
          border-top-left-radius: 100%;
        }
        &:before {
          border-top-right-radius: 100%;
        }
      }
     }
     &__text {
      position: relative;
      z-index: 2;
      @media (max-width: 768px) {
        display: flex;
        font-size: 14px;
        align-items: center;
      }
     }

     &__action {
      animation: shopWithText 3s ease-in-out infinite alternate;
      padding: 5px 20px;
      border-radius: 99px;
      background: var(--white);
      border: 1px solid #FFFFFF00;
      font-weight: 500;
      cursor: pointer;
      font-family: var(--primaryFont);
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      transition: border 0.3s ease-in-out, background 0.3s ease-in-out, color 0.3s ease-in-out;
      margin-left: 12px;
      @media (max-width: 768px) {
        margin-left: auto;
        padding: 8px 24px;
        font-size: 14px;
      }
      &:hover {
        background: transparent;
        animation: none;
        color: var(--white) !important;
        border: 1px solid var(--white);
      }
     }
  }

  .SwiperNav {
    display: flex;
    gap: 28px;

    &--small {
      svg {
        width: 28px;
      }
    }

    &--light {
      .SwiperNav__item {
        path {
          fill: var(--white);
        }

        ${desktopHover(`
          path {
            fill: var(--lime);
          }
        `)}
      }
    }

    @media (max-width: 1024px) {
      display: none;
    }

    .swiper-button-disabled {
      pointer-events: none;
      path {
        opacity: .2;
      }
    }

    &__item {
      background: none;
      border: none;
      padding: 0;
      display: block;
      cursor: pointer;

      &--prev {
        transform: rotateZ(180deg);
        margin-top: -9px;
      }

      path {
        fill: var(--black);
        transition: .25s ease;
        transition-property: fill, opacity;
      }

      ${desktopHover(`
        path {
          fill: var(--green);
        }
      `)}
    }
  }

  .AmbassadorBanner {
    position: fixed;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
    width: 90vw;
    max-width: 1396px;
    padding: .5rem 1rem .7rem;
    background: var(--lime);
    color: var(--green);
    z-index: 100;
    width: 90vw;
    border-radius: 12px;
    padding-right: 30px;
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    border: 1px solid var(--green);
    &.is-hidden, .cart-open & {
      opacity: 0;
      pointer-events: none;
      transform: translateX(-50%) translateY(100%);
    }
    .AmbassadorBanner__close {
      position: absolute;
      top: 50%;
      right: 1rem;
      transform: translateY(-50%);
      width: 1rem;
      height: 1rem;
      appearance: none;
      background: none;
      border: none;
      padding: 0;
      cursor: pointer;
      color: currentColor;
      &::after, &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 1px;
        background-color: currentColor;
      }
      &::after {
        transform: translate(-50%, -50%) rotate(45deg);
      }
      &::before {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
    p {
      margin: 0;
      font-family: var(--primaryFont);
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      align-items: center;
      color: var(--black);
      button {
        background: none;
        border: none;
        text-decoration: underline;
        padding: 0;
        margin: 0;
        appearance: none;
        color: currentColor;
      }
    }
  }

  @keyframes shopWithBackground {
    0% {
      background-color: var(--coral);
    }
    33% {
      background-color: var(--orangeDark);
    }
    66% {
      background-color: var(--orange);
    }
  }

  @keyframes shopWithText {
    0% {
      color: var(--coral);
    }
    33% {
      color: var(--orangeDark);
    }
    66% {
      color: var(--orange);
    }
  }

  @keyframes shopWithOutline {
    0% {
      outline-color: var(--coral);
    }
    33% {
      outline-color: var(--orangeDark);
    }
    66% {
      outline-color: var(--orange);
    }
  }

  @keyframes shapeAnimationScale {
    0% {
      transform: scale(0.01);
    }

    100% {
      transform: scale(1);
    }
  }

  @keyframes shapeAnimationSpring {
    0% {
      transform: scale(0) rotate(-170deg);
    }

    50% {
      transform: scale(0.75) translateY(-100%);
    }

    100% {
      transform: scale(1) translateY(0%) rotate(0deg);
    }
  }

  @keyframes shapeAnimationScaleMobile {
    0% {
      transform: scale(0) translateY(-10%);
    }

    50% {
      transform: scale(1) translateY(-10%);
    }

    100% {
      transform: scale(1) translateY(0%);
    }
  }
`;

export default GlobalStyles;
