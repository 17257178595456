import { useCart } from "@chordcommerce/gatsby-theme-autonomy"

export const useLineItemCount = () => {
  const { cart } = useCart();
  const { lineItems } = cart?.data;

  let lineItemCount = 0;

  lineItems?.forEach((item) => {
    lineItemCount += item.quantity;
  });

  return lineItemCount;  
}