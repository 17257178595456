import React, { PropsWithChildren, useEffect, useRef } from 'react';
import { useClickOutside, useScrollLock, useWindowEvent } from '@mantine/hooks';

import { Section } from '../../styles/Section.styled';
import { Cta } from '../Generic/Cta/Cta';
import { StyledModal } from './Modal.style';
import Portal from '~/components/Portal/Portal';
import gsap from 'gsap';
import { ThemeColor } from '~/types/types';

export const Modal: React.FC<PropsWithChildren<ModalProps>> = ({
  isOpen,
  onClose,
  children,
  styleProps
}) => {
  useScrollLock(isOpen, { disableBodyPadding: false });
  const timelineRef = useRef(gsap.timeline({ paused: true }));
  const modalRef = useRef<HTMLDivElement>(null);
  const clickRef = useClickOutside<HTMLDivElement>(onClose);

  useWindowEvent('keydown', (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      onClose();
    }
  });

  useEffect(() => {
    buildTimeline();
  }, []);

  useEffect(() => {
    if (isOpen) {
      timelineRef.current.play();
    } else {
      timelineRef.current.reverse();
    }
  }, [isOpen]);

  const buildTimeline = () => {
    timelineRef.current.to(modalRef.current, { pointerEvents: 'all' });
    // bg fade in
    timelineRef.current.to(modalRef.current.querySelector('.modal--bg'), {
      autoAlpha: 0.2,
      ease: 'power4.out',
      duration: 0.15,
    });
    // content fade in
    timelineRef.current.to(modalRef.current.querySelector('.modal--card'), {
      autoAlpha: 1,
      ease: 'power4.out',
      duration: 0.15,
      delay: 0.15,
      y: 0,
    });
  };
  
  const { bg = "lime", maxWidth, padding, fullScreenMobile = false, breakpoint, scrollable, hideCloseButton } = styleProps || {};
  return (
    <Portal>
      <StyledModal ref={modalRef} bg={bg} maxWidth={maxWidth} scrollable={scrollable} padding={padding} fullScreenMobile={fullScreenMobile} breakpoint={breakpoint}>
        <div className="modal--bg"></div>
        <Section gutter>
          <div className="modal--card" ref={clickRef}>
            {!hideCloseButton ? <Cta
              variant="secondary"
              href="#"
              className="modal--close"
              onClick={onClose}
            >
              Close
            </Cta> : null}
            {children}
          </div>
        </Section>
      </StyledModal>
    </Portal>
  );
};

export interface ModalProps {
  onClose: () => void;
  isOpen: boolean;
  styleProps?: {
    bg?: ThemeColor;
    maxWidth?: number;
    padding?: React.HTMLAttributes<HTMLElement>['style']['padding'];
    fullScreenMobile?: boolean;
    breakpoint?: number;
    scrollable?: boolean;
    hideCloseButton?: boolean;
  }
}
