import { ThemeColor } from '../types/types';
import styled from 'styled-components';

const widthMap: Map<string, string> = new Map();
widthMap.set('full', '100%');
widthMap.set('wide', '1920px');
widthMap.set('normal', '1280px');
widthMap.set('thin', '840px');

export const Wrapper = styled.div<WrapperProps>`
  --width: ${p => (p.width ? widthMap.get(p.width) : widthMap.get('full'))};
  --border-radius: ${p => (p.rounded === false ? '0' : 'var(--radius)')};
  display: flow-root;
  width: 100%;
  margin: 0 auto;
  border-radius: var(--border-radius);

  --background: ${p => (p.bg ? `var(--${p.bg})` : 'transparent')};
  background: var(--background);
  color: var(--color);
  overflow: ${p => p.allowOverflow ? 'visible' : 'hidden'};

  ${p =>
    p.gutter === false
      ? 'max-width: var(--width);'
      : 'max-width: calc(var(--width) + var(--gutter) * 2);padding: var(--gutter);'}
`;

export interface WrapperProps {
  bg?: ThemeColor;
  width?: WrapperWidths;
  gutter?: boolean;
  rounded?: boolean;
  allowOverflow?: boolean;
}

export type WrapperWidths = 'full' | 'wide' | 'normal' | 'thin';
