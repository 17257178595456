export const intentSlugs = [
  'sleep-relaxation',
  'energy-focus',
  'clarity-calm',
  'relief-recovery',
  'wellbeing-balance',
] as const;

export const AMBASSADOR_ENROLLMENT = {
  slug: 'ambassador-enrolment',
  sku: '100000'
} as const

export const AMBASSADOR_ENROLLMENT_MAXDATE = new Date('2023-02-25 08:00:00Z'); // midnight for our west coast friends

export const RANKS = {
  '0': {
    name: 'AM0',
    msr: 0,
  },
  '10': {
    name: 'AM1',
    msr: 0,
  },
  '20': {
    name: 'AM2',
    msr: 0,
  },
  '30': {
    name: 'AM3',
    msr: 0,
  },
  '40': {
    name: 'AMB1',
    msr: 800,
  },
  '50': {
    name: 'AMB2',
    msr: 1600,
  },
  '60': {
    name: 'AMB3',
    msr: 3200,
  },
  '70': {
    name: 'AMC1',
    msr: 6400,
  },
  '80': {
    name: 'AMC2',
    msr: 14000,
  },
  '90': {
    name: 'AMC3',
    msr: 30000,
  },
} as const;


export const paymentTypesMap: any = {
  paid: 'Paid',
} as const;

export const shippingStatusesMap: any = {
  ready: 'Ready to Ship',
  shipped: 'Fulfilled',
  backorder: 'Back Order',
} as const;