import React from 'react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { useTheme } from 'styled-components';
import { Link } from 'gatsby';

import { SubMenu } from '../Header';
import { intentToColor } from '../../../utils/colors';
import { intentSlugs } from '~/types/constants';

import Shape5 from '~/assets/images/shapes/shape5.svg';
import Arrow from '~/assets/images/icons/arrow.svg';

import { StyledSubmenu } from './Submenu.style';
import { IntentSlug } from '~/types/types';

export const Submenu: React.FC<SubmenuProps> = ({ columns, image }) => {
  return (
    <StyledSubmenu>
      <div className="submenu__content">
        {columns.map(({ title, items }, i) => (
          <div
            key={`column-${i}`}
            className={`submenu__column submenu__column--${i}`}
          >
            <p className="submenu__columnTitle">{title}</p>
            <ul className="submenu__list">
              {items.map(({ slug, name, productType, __typename , url}, ii) => {
                let finalSlug = slug || url;
                if (__typename === 'ContentfulCollection') {
                  finalSlug = `/shop/${productType ? slug : 'all'}/${productType ? 'all' : slug}/`.split('/all/all/').join('/');
                }
                return (
                <MenuItem key={`${ii}-${slug}`} finalSlug={finalSlug} slug={slug} name={name} />
              )})}
            </ul>
          </div>
        ))}
        <div className={`submenu__column submenu__column--${columns.length}`}>
          <GatsbyImage image={image.gatsbyImageData} alt={image.title} />
        </div>
      </div>
    </StyledSubmenu>
  );
};

const MenuItem = ({ slug, name, finalSlug }: { slug: string; name: string, finalSlug?: string }) => {
  const theme = useTheme();
  let href = finalSlug || slug;
  if (href?.indexOf?.('/') !== 0 && href?.indexOf?.(':/') === -1) {
    href = `/${href}`;
  }

  if (intentSlugs.includes(slug as IntentSlug)) {
    return (
      <li className={`submenu__listItem submenu__listItem--blob`}>
        <Shape5 fill={intentToColor(slug as IntentSlug)} />
        <Link to={href}>{name}</Link>
      </li>
    );
  } else {
    return (
      <li className={`submenu__listItem`}>
        <Arrow fill={theme.colors.black} />
        <Link to={href}>{name}</Link>
      </li>
    );
  }
};

interface SubmenuProps extends SubMenu {}
