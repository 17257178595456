import { css } from "styled-components";
import theme from "../styles/theme";

/*
 * @description Clamp an input between two number
 * @param input
 * @param min Min Value
 * @param max Max Value
 */
export function Clamp(input: number, min: number, max: number): number {
  return input < min ? min : input > max ? max : input;
}

export function gearRatio(
  current: number,
  in_min: number,
  in_max: number,
  out_min: number,
  out_max: number
): number {
  return (
    ((current - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min
  );
}

export const pxtorem = (
  size: number,
  base: number = theme.fonts.fontSize
): string => {
  return `${(size / base).toPrecision(4)}rem`;
};

export const pxtovw = (px: number, viewport: number = 1920): string => {
  return `${((px * 100) / viewport).toPrecision(4)}vw`;
};

export const keepRatio = (
  dimension: string,
  useAbsolute: boolean = false
): string => {
  const w = +dimension.split("/")[0];
  const h = +dimension.split("/")[1];
  return `
  position: ${useAbsolute ? "absolute" : "relative"};
  @supports (aspect-ratio: 1/1) {
    aspect-ratio: ${dimension};
  }
  @supports not (aspect-ratio: 1 / 1) {
    &:before {
      content: '';
      display: block;
      padding-top: ${(h * 100) / w}%
    }
  }
  `;
};

export const desktopHover = (content: string, addFocus: boolean = true) => {
  return css`
    @media (hover: hover) {
      &:hover${addFocus ? ", &:focus" : null} {
        ${content}
      }
    }
  `;
};

export const MaybeWindow: Window =
  typeof window !== "undefined" ? window : null;

export interface OffsetObject {
  top: number;
  left: number;
  right: number;
  bottom: number;
  height?: number;
  width?: number;
}

interface Rect extends ClientRect {
  x: number;
  y: number;
}
export const getOffset = (
  el: HTMLElement,
  parent: HTMLElement = document.body
): OffsetObject => {
  const rect: Rect = el.getBoundingClientRect() as Rect;
  const rectParent: Rect = parent.getBoundingClientRect() as Rect;

  const parentObj: OffsetObject = {
    left: rectParent.left + window.scrollX,
    right: rectParent.right + window.scrollX,
    top: rectParent.top + window.scrollY,
    bottom: rectParent.bottom + window.scrollY,
  };

  return {
    left: rect.left + window.scrollX - parentObj.left,
    right: rect.right + window.scrollX - parentObj.left,
    top: rect.top - parentObj.top + window.scrollY,
    bottom: rect.bottom - parentObj.top + window.scrollY,
    height: rect.height,
    width: rect.width,
  };
};

export const lerp2D = (p1: Point, p2: Point, t: number = 0.5): Point => {
  const x: number = p1.x + (p2.x - p1.x) * t;
  const y: number = p1.y + (p2.y - p1.y) * t;
  return {
    x,
    y,
  };
};

export const lerp = (p1: number, p2: number, t: number = 0.5): number => {
  const x: number = p1 + (p2 - p1) * t;
  return +x.toFixed(4);
};

interface Point {
  x: number;
  y: number;
}

export const scrollToSelector = (target: string): void => {
  if (typeof window === "undefined") return;
  const el: HTMLElement = document.querySelector(target);
  if (!el) return;

  window.scrollTo(0, window.scrollY + el.getBoundingClientRect().top - 140);
  window.history.pushState(null, null, `#${target.replace("#", "")}`);
};

export function toHyphenCase(camelString: string): string {
  return camelString.replace(
    /[A-Z]/g,
    (match, offset) => (offset > 0 ? "-" : "") + match.toLowerCase()
  );
}

export function slugify(text: string): string {
  return text
    .toString()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase()
    .trim()
    .replace(/\s+/g, "-")
    .replace(/[^\w-]+/g, "")
    .replace(/--+/g, "-");
}

export function getMdData<T = {}>(data: MarkdownData<T>["data"]): T {
  return data.markdownRemark?.frontmatter || ({} as T);
}

export function getLocaleMdData<T = {}>(
  data: LocalizedMarkdownData<T>["data"]
): { en: T; fr: T } {
  return data.markdownRemark?.fields.locales || { en: null, fr: null };
}

export function replaceNewLineWithFake(str: string): string {
  return str.replace(/[\n]/g, "@linebreak@");
}

export function replaceFakeNewLine(str: string): string {
  return str.replace(/@linebreak@/g, "\n");
}

export function getDistance(pos1: Vector, pos2: Vector): number {
  const a: number = pos1.x - pos2.x;
  const b: number = pos1.y - pos2.y;
  return Math.sqrt(a * a + b * b);
}

export function pxToPercent(px: number, base: number = 110): number {
  return +(px / base).toPrecision(4);
}

export const getCurrentUrl = (): string =>
  window.location.protocol +
  "//" +
  window.location.host +
  window.location.pathname;

export function findClosestByTagName(
  el: HTMLElement,
  tag: string
): HTMLElement {
  while ((el = el.parentElement) && el.tagName.toLowerCase() !== tag);
  return el;
}

export function exportToCsv(filename: string, rows: any[]) {
  var processRow = function (row: any) {
    var finalVal = '';
    for (var j = 0; j < row.length; j++) {
      var innerValue = row[j] === null ? '' : row[j].toString();
      if (row[j] instanceof Date) {
        innerValue = row[j].toLocaleString();
      }
      var result = innerValue.replace(/"/g, '""');
      if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
      if (j > 0) finalVal += ',';
      finalVal += result;
    }
    return finalVal + '\n';
  };

  var csvFile = '';
  for (var i = 0; i < rows.length; i++) {
    csvFile += processRow(rows[i]);
  }

  var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
  if ((navigator as any).msSaveBlob) {
    // IE 10+
    (navigator as any).msSaveBlob(blob, filename);
  } else {
    var link = document.createElement('a');
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}


export function formatPhoneNumber(phoneNumberString: string) {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = (match[1] ? '+1 ' : '');
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
}