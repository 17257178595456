import styled from 'styled-components';
import { pxtorem } from '../../utils/tools';
import { StyledAmbassadorPLPCard } from '../AmbassadorPLPCard/AmbassadorPLPCard.style';
import { StyledDropdown } from '../Generic/Dropdown/Dropdown.style';

export const StyledPLP = styled.div`
  padding-top: ${pxtorem(35)};

  .wrapper {
    padding: ${pxtorem(0)} min(${pxtorem(80)}, 5%);

    @media (min-width: 1920px) {
      max-width: 1920px;
      margin: 0 auto;
    }

    @media (max-width: 480px) {
      padding: ${pxtorem(0)} ${pxtorem(20)};
    }
  }

  .cta-animation {
    overflow: hidden;
  }

  .plp--text-banner {
    h1 {
      margin-bottom: 40px;
      line-height: 90%;
      max-width: 62.5vw;

      em {
        line-height: 100%;
      }
    }

    @media (max-width: 1440px) {
      h1 {
        font-size: ${pxtorem(60)};

        em {
          font-size: ${pxtorem(50)};
        }
      }
    }

    @media (max-width: 1024px) {
      h1 {
        max-width: 100%;

        font-size: ${pxtorem(40)};

        em {
          font-size: ${pxtorem(36)};
        }
      }
    }
  }

  .plp--filter--show-scroll {
    position: relative;
    z-index: 10;
  }

  .modal-cta {
    padding-left: 0;
    text-transform: uppercase;
    font-size: ${pxtorem(16)};
    font-weight: 300;
    line-height: 1;
    font-weight: 400;
    margin-bottom: ${pxtorem(72)};

    @media (max-width: 768px) {
      margin-bottom: ${pxtorem(40)};
    }

    @media (max-width: 512px) {
      width: auto;
    }
  }

  .plp--filters {
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    flex-flow: row;
    justify-content: space-between;
    width: 100%;

    .dropdowns {
      display: flex;
      align-items: flex-start;
      width: 100%;
      max-width: 600px;

      > :first-child {
        margin-right: ${pxtorem(16)};
      }

      ${StyledDropdown} {
        max-width: 220px;
      }  
    }

    .sort-wrapper {
      padding-top: ${pxtorem(16)};

      .cta--secondary {
        font-weight: 300;
        text-decoration-thickness: 1px;
        font-size: ${pxtorem(20)};

        padding-inline: 0;

        @media (max-width: 768px) {
          font-size: ${pxtorem(16)};
        }
      }

      @media (max-width: 1024px) {
        width: auto;
        align-self: flex-end;
      }
    }

    @media (max-width: 512px) {
      flex-flow: column;

      .dropdowns {
        justify-content: space-between;
      }

      .cta--secondary {
        padding-inline: ${pxtorem(18)};
      }

      .dropdowns {
        width: 100%;
      }
    }
  }

  .empty-filter {
    font-size: ${pxtorem(32)};
    text-align: center;
    margin-bottom: 0;
  }

  .filter--reset {
    font-weight: 300;
    display: block;
    text-align: center;
    margin-inline: auto;
    text-decoration-thickness: 1px;
  }
`;

export const PLPGrid = styled.div`
  display: grid;
  place-content: center;
  place-items: center;
  grid-template-columns: 1fr;
  grid-template-rows: 440px;
  grid-gap: ${pxtorem(20)};
  padding-top: ${pxtorem(40)};
  margin: 0 auto;
  position: relative;
  width: 100%;
  z-index: 0;
  padding-bottom: ${pxtorem(85)};
  justify-items: center;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 360px);
  }

  @media (min-width: 1280px) {
    max-width: 77%;
    grid-template-columns: repeat(3, 360px);
    padding-top: ${pxtorem(85)};
  }

  .ambassador-signup {
    grid-column: 1 / span 1;
    height: 100%;
    width: 100%;

    ${StyledAmbassadorPLPCard} {
      max-height: 440px;
    }

    @media (max-width: 767px) {
      aspect-ratio: 360 / 440;
      margin: 0;
      padding-inline: 0;
      max-width: 360px;
    }

    @media (min-width: 768px) {
      grid-column: 1 / span 2;
    }
  }
`;

export const StyledPLPModal = styled.div`
  height: 100%;
  width: 100%;

  .modal--title {
    margin: 0;
    text-transform: uppercase;
    font: 500 ${pxtorem(16)} / 1.111 var(--primaryFont);
    margin-bottom: 8px;
  }

  .modal--content {
    font: 300 ${pxtorem(60)} / 96% var(--primaryFont);
    margin: 0;
    margin-bottom: ${pxtorem(28)};

    p {
      margin: 0;
      &:first-child {
        margin-bottom: ${pxtorem(20)};
      }
    }

    @media (max-width: 1024px) {
      line-height: 100%;
      font-size: ${pxtorem(32)};
    }

    @media (max-width: 768px) {
      font-size: ${pxtorem(28)};
    }
  }

  .modal--link {
    padding-inline: 0;
    width: auto;
  }
`;
