import { graphql, useStaticQuery } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';
const useNavigationQuery = (): NavigationQueryData => {
  const data = useStaticQuery(
    graphql`
      query NavigationQuery {
        main: contentfulNavigationBar(slug: { eq: "header-main-nav" }) {
          pages {
            slug
            name
          }
        }
        shopSubmenu: contentfulSubmenu(slug: { eq: "shop" }) {
          columns {
            id
            title
            items {
              __typename
              ... on ContentfulNavigationLink {
                name
                url
              }
              ... on ContentfulCollection {
                slug
                name
                productType
              }
              ... on ContentfulPage {
                slug
                name
              }
            }
          }
          image {
            title
            gatsbyImageData(
              quality: 80
              placeholder: BLURRED
              layout: CONSTRAINED
              width: 400
            )
          }
        }
      }
    `
  );

  return data;
};

interface NavigationQueryData {
  main: {
    pages: NavItem[];
  };
  shopSubmenu: {
    columns: {
      id: string;
      title: string;
      items: NavItem[];
    }[];
    image: {
      title: string;
      gatsbyImageData: IGatsbyImageData;
    };
  };
}

interface NavItem {
  slug: string;
  name: string;
}

export default useNavigationQuery;
