import styled from 'styled-components';
import { pxtorem } from '../../utils/tools';

export const StyledAmbassadorPLPCard = styled.article`
  height: 100%;
  width: 100%;
  padding-top: ${pxtorem(51)};
  padding-inline: ${pxtorem(16)};
  border-radius: 10px;
  padding-bottom: ${pxtorem(44)};

  background-color: var(--lime);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;


  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  h3 {
    margin-bottom: 0;
    text-align: center;
  }

  @media (max-width: 768px) {
    grid-column: 1 / span 1;
  }

  .cta--primary, .cta--secondary {
    font-weight: 500;
    margin-bottom: ${pxtorem(20)};
    width: auto;
  }
  
  .shapes {
    display: flex;
    align-items: flex-end;
    gap: 8px;
    width: 90%;
    margin-inline: auto;
    justify-content: center;

    & .spring {
      svg {
        transform: scale(0) rotate(-170deg);
      }

      &.visible svg {
        animation: shapeAnimationSpring 0.6s var(--cubic-ease) forwards;
      }
    }

    & .scale {
      svg {
        transform: scale(0);
      }
      &.visible svg {
        animation: shapeAnimationScale 0.5s var(--cubic-ease) forwards;
      }
    }

    &__container {
      display: flex;
      align-items: flex-end;
      height: auto;


      & svg {
        width: 100%;
        height: auto;
      }
    }
  }
`;
