import styled from 'styled-components';
import { pxtorem } from '../../utils/tools';
import { StyledBtn } from '../Generic/Cta/Cta.style';

export const StyledCartItem = styled.div<StyledCartItemProps>`
  display: flex;
  align-items: stretch;
  gap: ${pxtorem(18)};
  margin-bottom: ${pxtorem(16)};

  p {
    margin: 0;
  }

  .product-link {
    pointer-events: ${({isEnrolment}) => isEnrolment ? 'none' : 'unset'};
  }

  .content {
    width: 100%;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    justify-content: space-between;
  }

  .img-container {
    width: 112px;
    &:after {
      content: '';
      display: block;
      padding-bottom: 100%;
    }
    position: relative;
    overflow: hidden;

    img {
      position: absolute;
      width: 112px;
      height: auto;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      object-fit: contain;
    }
  }

  .item-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .item-name {
      max-width: 20ch;
      font: 400 ${pxtorem(16)} / 117% var(--primaryFont);

      a {
        color: inherit;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }

      @media (max-width: 768px) {
        font-size: 12px;
      }
    }

    .item-sub-text {
      font: 400 ${pxtorem(12)} / 117% var(--primaryFont);
    }
  }

  .item-controls {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: ${({isEnrolment, isSharedOrder}) => isEnrolment || isSharedOrder ? 'flex-end' : 'space-between'};

    ${StyledBtn} {
      width: auto;
    }
  }

  .remove-btn {
    padding: 8px 0px;
    padding-bottom: 0;
    
    .cta__label {
      font-weight: 400;
    }

    &:after {
      height: 1px;
    }

    &:after {
      bottom: -1px;
    }

    &:hover {
      text-decoration-color: var(--red);
      color: var(--red);
    }
  }
`;

interface StyledCartItemProps {
  isEnrolment: boolean;
  isSharedOrder?: boolean;
}