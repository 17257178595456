import React, { useEffect } from 'react';
import { Link } from 'gatsby';
import { useDisclosure } from '@mantine/hooks';

import BurgerSvg from '~/assets/images/icons/burger.svg';
import CloseSvg from '~/assets/images/icons/close.svg';
import LogoSvg from '~/assets/images/logos/logo.svg';
import { Wrapper } from '~/styles/Wrapper.styled';
import { PageItem, SubMenu } from '../Header';
import { Submenu } from '../Submenu/Submenu';
import ProfileIcon from '~/assets/images/icons/profile.svg';

import { StyledMobileNav, StyledMobilePanel } from './MobileNav.style';
import { useCartContext } from '../../CartContext/CartContext';
import { CartBadgeButton } from '~/components/Cart/CartBadgeButton/CartBadgeButton';
import { AMBASSADOR_ENROLLMENT_MAXDATE } from '~/types/constants';

const now = new Date();

export const MobileNav: React.FC<MobileNavProps> = ({
  pages,
  shopSubmenu,
  quicklinks,
}) => {
  const [isMobileNavOpened, mobileNavHandlers] = useDisclosure(false);
  const { toggleCart } = useCartContext();

  useEffect(() => {
    document.body.classList.toggle('menu-opened', isMobileNavOpened);
  }, [isMobileNavOpened]);

  return (
    <>
      <StyledMobileNav>
          <nav className="mobilenav__navs">
            <div className="mobilenav__nav mobilenav__nav--left">
              <button
                type="button"
                className={`svgIcon svgIcon--${
                  isMobileNavOpened ? 'close' : 'burger'
                }`}
                onClick={() => {
                  mobileNavHandlers.toggle();
                }}
              >
                {isMobileNavOpened ? <CloseSvg /> : <BurgerSvg />}
              </button>
            </div>
            <div className="mobilenav__nav mobilenav__nav--center">
              <div className="logo">
                <Link to="/">
                  <LogoSvg />
                </Link>
              </div>
            </div>
            <div className="mobilenav__nav mobilenav__nav--right">
              <ul>
                {quicklinks.map(({ slug, name, type = 'link' }, i) => {
                  const isCartLink = slug === '/cart';
                  const isProfileLink = slug === '/account';
                  return (
                    <li key={`${i}-${type}-${slug}`} className={`mobilenav__${type}`}>
                      {type === 'separator' ? null : (isCartLink ? (
                        <a
                          href="#"
                          onClick={e => {
                            e.preventDefault();
                            toggleCart();
                          }}
                        >
                          <CartBadgeButton name={name} />
                        </a>
                      ) : (
                        <Link to={slug}>
                          {isProfileLink ? <ProfileIcon title={name} /> : name}
                        </Link>
                      ))}
                    </li>
                  );
                })}
              </ul>
            </div>
          </nav>
      </StyledMobileNav>
      <StyledMobilePanel
        className={`submenu submenu--opened ${
          isMobileNavOpened ? 'mobilenav--opened' : ''
        }`}
      >
        <Wrapper gutter>
          <ul>
            {pages?.filter(({slug}) => slug === 'join' && now.getTime() > AMBASSADOR_ENROLLMENT_MAXDATE.getTime() ? false : true).map(({ slug, name, type = 'link'}, i) => (
              <li key={`${i}-${slug}`} className={`submenu__${type}`}>
                {(slug || name) && <Link to={`/${slug}`} onClick={mobileNavHandlers.close}>{name}</Link>}
                {slug === 'shop' && shopSubmenu ? (
                  <Submenu {...shopSubmenu} />
                ) : null}
              </li>
            ))}
          </ul>
        </Wrapper>
      </StyledMobilePanel>
    </>
  );
};

interface MobileNavProps {
  pages: PageItem[];
  shopSubmenu: SubMenu;
  quicklinks: PageItem[];
}
