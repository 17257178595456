import { graphql, useStaticQuery } from 'gatsby';

const useFooterQuery: FooterQueryData = () => {
  const data = useStaticQuery(
    graphql`
      query FooterQuery {
        footerSubmenu: contentfulSubmenu(slug: { eq: "footer" }) {
          columns {
            id
            title
            items {
              ... on ContentfulNavigationLink {
                name
                url
              }
              ... on ContentfulCollection {
                slug
                name
              }
              ... on ContentfulPage {
                slug
                name
              }
            }
          }
        }
      }
    `
  );

  return data;
};

interface FooterQueryData {
  (): {
    footerSubmenu: {
      columns: {
        id: string;
        title: string;
        items: {
          slug?: string;
          name: string;
          url?: string;
        }[];
      }[];
    };
  };
}

export default useFooterQuery;
