import React, { PropsWithChildren } from 'react';
import { StyledCheckbox } from './Checkbox.style';

export const Checkbox = React.forwardRef<HTMLInputElement, PropsWithChildren<CheckboxProps>>(({
  children,
  isInvalid,
  ...props
}, ref) => {
  return (
    <StyledCheckbox isInvalid={isInvalid}>
      <input type="checkbox" {...props} ref={ref} />
      <span>{children}</span>
    </StyledCheckbox>
  );
});

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  isInvalid?: boolean;
}
