import styled from 'styled-components';
import { pxtorem, desktopHover } from '~/utils/tools';

export const StyledSubmenu = styled.div`
  @media (max-width: 767px) {
    overflow-y: scroll;
  }

  @media (min-width: 768px) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    top: 100%;
    background: var(--white);
    padding: var(--gutter) 64px 64px 64px;
    border-bottom-left-radius: var(--radius);
    border-bottom-right-radius: var(--radius);
    visibility: hidden;
    pointer-events: none;
    opacity: 0;
    transform-origin: center top;
    transition: opacity 250ms 250ms, visibility 0ms 6000ms;

    &:before {
      content: '';
      position: absolute;
      top: -10px;
      left: 0;
      right: 0;
      height: 20px;
      background: var(--white);
    }
  }

  .submenu__content {
    gap: ${pxtorem(24)};
    ul {
      width: 100%;
    }
    @media (min-width: 768px) {
      display: flex;
      justify-content: space-between;
    }
  }

  .submenu__column {
    visibility: hidden;
    opacity: 0;
    transform: translateY(-20%);
    transition: opacity 250ms, transform 400ms var(--cubic-ease), visibility 0ms;

    margin-top: 2rem;
    @media (min-width: 768px) {
      margin: 0;
    }

    &--0 {
      transition-delay: 0ms, 0ms, 500ms;
    }
    &--1 {
      transition-delay: 50ms, 50ms, 500ms;
      @media (max-width: 768px) {
        display: none;
      }
    }
    &--2 {
      transition-delay: 100ms, 100ms, 500ms;
      ul li a {
        white-space: nowrap;
      }
    }
    &--3 {
      transition-delay: 150ms, 150ms, 500ms;
      @media (max-width: 768px) {
        display: none;
      }
    }
  }

  .submenu__columnTitle {
    text-transform: uppercase;
    font-weight: 500;
    font-size: ${pxtorem(12)};

    @media (min-width: 768px) {
      font-size: ${pxtorem(16)};
    }
  }

  .submenu__list {
    font-weight: 300;
    font-size: ${pxtorem(16)};
    @media (min-width: 768px) {
      font-size: ${pxtorem(20)};
    }
  }

  .submenu__listItem {
    margin-bottom: 0.8rem;
    list-style-type: none;
    align-items: center;
    display: flex;

    > a {
      color: var(--black);
      text-decoration: none;
      transition: color 250ms;

      ${desktopHover(`
          color: var(--green);
        `)}
    }

    > svg {
      margin-right: 0.5em;
      @media (max-width: 767px) {
        display: none;
      }
    }

    &--blob {
      position: relative;
      margin-left: 0;
      > svg {
        width: 20px;
        height: auto;
        @media (max-width: 767px) {
          display: block;
        }
      }
    }
  }

  img {
    width: 100%;
    height: auto;
    border-radius: var(--radius);
  }

  .submenu--opened & {
    visibility: visible;
    opacity: 1;
    transition-delay: 0ms;

    @media (min-width: 768px) {
      pointer-events: auto;
    }

    .submenu__column {
      visibility: visible;
      opacity: 1;
      transform: translateY(0);

      &--0 {
        transition-delay: 250ms, 200ms, 0ms;
      }

      &--1 {
        transition-delay: 300ms, 250ms, 0ms;
      }

      &--2 {
        transition-delay: 350ms, 300ms, 0ms;
      }

      &--3 {
        transition-delay: 400ms, 350ms, 0ms;
      }
    }
  }
`;
