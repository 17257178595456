import styled from 'styled-components';
import { pxtorem } from '../../utils/tools';
import { StyledLink } from '../Generic/Cta/Cta.style';

export const StyledMiniCart = styled.div<StyledMiniCartProps>`
  --summary-height: ${({summaryHeight}) => summaryHeight}px;
  --header-height: calc(56px + 30px);

  position: relative;
  background-color: var(--white);
  height: 100%;

  @media (max-width: 768px) {
    overflow-y: auto;
  }
  
  .cart-header {
    position: relative;
    pointer-events: none;
    margin-top: 12px;
    margin-bottom: ${pxtorem(40)};

    h3 {
      margin-bottom: 1rem;
      font-size: ${pxtorem(40)};
    }

    @media (max-width: 768px) {
      padding-bottom: ${pxtorem(12)};
      margin-bottom: ${pxtorem(8)};
      padding-top: ${pxtorem(12)};
      margin-top: 0;
      position: sticky;
      top: 0;
      z-index: 3;
      background: #fff;
      &:after {
        content: '';
        position: absolute;
        bottom: -16px;
        left: 0;
        right: 0;
        height: 16px;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
      }
    }
  }

  .cart-header-message {
    pointer-events: auto;
    background: var(--lime);
    border-radius: 10px;
    padding: 1rem;
    margin-block: 1rem;
    p {
      margin: 0;
    }
  }

  .padding {
    padding-left: var(--x-padding);
    padding-right: var(--x-padding);
  }

  .carousel-wrapper {
    padding-left: var(--x-padding);

    .swiper-scrollbar {
      max-width: calc(100% - var(--x-padding));
      overflow: hidden;
      &-drag {
        margin-right: var(--x-padding);
      }
    }
  }

  .scroll-area {
    width: 100%;
    z-index: 2;
    max-height: calc(100vh - var(--summary-height) - 112px);
    position: relative;
    overflow-y: auto;
    ${p => p.isSharedOrder ? `padding-bottom: calc(var(--summary-height) + 96px)` : `padding-bottom: calc(var(--summary-height) / 2);`};
    
    @media (max-width: 768px) {
      max-height: none;
      ${p => p.isSharedOrder ? `padding-bottom: calc(var(--summary-height) + 96px)` : `padding-bottom: 0`};
    }
  }
  
  .cart-content {
    background-color: inherit;
    padding-top: ${pxtorem(24)};

    @media (min-width: 769px) {
      padding-bottom: ${pxtorem(24)};
    }
  }

  .amb-wrapper {
    padding-bottom: 0;
    padding-top: 0;
  }


  .ambassador-notice {
    .section-label {
      margin: 0;
      font: 500 12px / 117% var(--primaryFont);
      text-transform: uppercase;
      margin-bottom: ${pxtorem(8)};
    }

    .section-desc {
      font: 300 ${pxtorem(32)} / 100% var(--primaryFont);

      p {
        margin: 0;
      }

      @media (max-width: 768px) {
        font-size: ${pxtorem(24)};
      }
    }
  }

  .empty-cart-cta {
    position: absolute;
    left: 50%;
    top: 35%;
    transform: translateX(-50%);
    text-align: center;
    width: 100%;
    p {
      width: 100%;
      margin-top: 0;
      margin-inline: auto;
      margin-bottom: ${pxtorem(24)};
      font-size: clamp(${pxtorem(48)}, 4.166667vw, ${pxtorem(60)});
    }

    ${StyledLink} {
      font-weight: 500;
      font-size: ${pxtorem(20)};
    }

    @media (max-width: 768px) {
      top: 18.5%;
    }
  }
`;

interface StyledMiniCartProps {
  summaryHeight?: number;
  isSharedOrder?: boolean;
}
