import { useCart, useUser } from '@chordcommerce/gatsby-theme-autonomy';
import { useDisclosure } from '@mantine/hooks';
import { useLocation } from '@reach/router';
import React, { EventHandler, FormEventHandler, useCallback, useMemo, useState } from 'react';
import {Link} from 'gatsby';
import { useCartContext } from '../CartContext/CartContext';
import { Checkbox } from '../Generic/Checkbox/Checkbox';
import { Cta } from '../Generic/Cta/Cta';
import { StyledCartSummary } from './CartSummary.style';

export const CartSummary = React.forwardRef<HTMLDivElement, CartSummaryProps>(({}, ref) => {
  const { cart, forgetCart, modifyCart } = useCart();
  const { isCartEmpty, closeCart } = useCartContext();
  const [hasSubmitForm, hasSubmitHandlers] = useDisclosure(false);
  const [termsAccepted, termsHandlers] = useDisclosure(false);
  
  const { adjustmentTotal, displayItemTotal, itemTotal, taxTotal } = cart.data;
  const discountAmount = Math.abs(+adjustmentTotal - +taxTotal).toFixed(2);

  const location = useLocation();
  const isAmbassadorPortal = false && location?.pathname?.includes?.('/account/ambassador');
  const { user } = useUser();

  const handleCheckout: FormEventHandler = (event) => {
    hasSubmitHandlers.open();
    event.preventDefault();
    window.location.href = '/checkout'
  };

  const [hasCopied, setHasCopied] = useState(false);
  const orderUrl = useMemo(() => `${window?.location?.origin}/cart#number=${cart?.data?.number}&token=${cart?.data?.token}`, [cart]);

  const handleOrderCopy = useCallback((e: React.MouseEvent) => {
    navigator.clipboard.writeText(`${orderUrl}`);
    setHasCopied(true);
    setTimeout(() => setHasCopied(false), 3000);
  }, [orderUrl, hasCopied, setHasCopied]);

  const forgetOrder = async () => {
    await forgetCart();
    closeCart();
    await modifyCart({metadata: {
      ambassadorId: user?.data?.id,
      ambassador_id: user?.data?.id
    }})
  };

  const isFormDisabled = !termsAccepted || isCartEmpty;
  return (
    <StyledCartSummary ref={ref}>
      <hr />
      <div className="summary">
        <div className="flex-between">
          <p>Subtotal</p>
          <p>{displayItemTotal}</p>
        </div>
        <div className="flex-between">
          <p>Discount</p>
          <p>${discountAmount}</p>
        </div>
      </div>
      <hr />
      <div className="flex-between total">
        <p>Total</p>
        <p>{(+itemTotal - +discountAmount).toFixed(2)}</p>
      </div>
      <hr />
      {isAmbassadorPortal && typeof window !== 'undefined' && cart?.data?.number && (
        <div className="cart-ambassador-copy">
          <p className="cart-ambassador-copy-alert">Heads up! This unique link will only work for one customer at a time. Share this cart thoughtfully 🙂</p>
          <label>Shareable link<input type="text" readOnly value={orderUrl} onFocus={(e: any) => e.target.select()} /></label>
          <div className="cart-ambassador-copy-actions">
            <Cta variant={"primary"} onClick={handleOrderCopy} disabled={hasCopied}>
              {hasCopied ? 'The link has been copied to your clipboard!' : 'Copy shareable link'}
            </Cta>
            <Cta href="#" onClick={forgetOrder}>
              Start a new order
            </Cta>
          </div>
        </div>
      )}
      {!isAmbassadorPortal && (
        <form onSubmit={handleCheckout}>
          <div style={{ padding: '20px 0' }}>
            <Checkbox
              name="termsAccepted"
              required
              onChange={e =>{
                if (e.target.checked) {
                  termsHandlers.open();
                } else {
                  termsHandlers.close();
                }
              }}
              isInvalid={hasSubmitForm && !termsAccepted}
              disabled={isCartEmpty}
            >
              I have read and agreed to the <Cta href={'/terms-of-service'} variant="secondary">Terms</Cta> and <Cta variant="secondary" href={'/privacy-policy'}>Privacy Policy</Cta>
            </Checkbox>
          </div>
          <Cta href="#" variant={isFormDisabled ? undefined : "primary"}size="large" type="submit" disabled={isFormDisabled} >
            Checkout
          </Cta>
          <p className="tax-info">Taxes and shipping are calculated at Checkout</p>
        </form>
      )}
    </StyledCartSummary>
  );
});

interface CartSummaryProps {}
