import styled from 'styled-components';
import { pxtorem } from '../../utils/tools';

export const StyledUpsellCarousel = styled.div<StyledUpsellCarouselProps>`
  --card-width: 214px;
  --card-height: 261px;

  width: 100%;
  margin-top: ${pxtorem(40)};

  .upsellCarousel {
    &__title {
      padding-right: var(--x-padding);
      display: flex;
      justify-content: space-between;
      margin-bottom: ${pxtorem(16)};
    }
  }
  
  .small-label {
    font: 500 ${pxtorem(16)} / 117% var(--primaryFont);
    text-transform: uppercase;
    display: block;

    @media (max-width: 768px) {
      margin-bottom: ${pxtorem(20)};
      font-size: 12px;
    }
  }
  
  .swiper-slide {
    width: var(--card-width);
  }
  
  .carousel {
    min-height: calc(var(--card-height) + ${pxtorem(20)});
  }
  
  .swiper-scrollbar {
    height: 1px !important;
    width: 100%;
    background-color: #0000001a;
    bottom: 0;
  }
  
  .swiper-scrollbar-drag {
    background-color: #393d2a;
    height: 1px !important;
  }
  
  @media (max-width: 768px) {
    --card-width: 158px;
    --card-height: 190px;
  }
`;

interface StyledUpsellCarouselProps {
  maxCardHeight: number;
}