import React, { FC, useEffect, useState } from 'react';
import { logSentryError, useCart } from '@chordcommerce/gatsby-theme-autonomy';
import MinusSVG from '~/assets/images/icons/minus.svg';
import { StyledQuantitySelector } from './QuantitySelector.style';

const CartQuantitySelector: FC<CartQuantitySelectorProps> = ({
  quantity,
  lineItemId,
}) => {
  const { modifyLineItem, removeFromCart } = useCart();
  const [isLoading, setIsLoading] = useState(false);

  const updateQuantity = async (quantity: string | number) => {
    setIsLoading(true);

    const intQuantity = parseInt(quantity.toString());
    try {
      if (intQuantity === 0) {
        await removeFromCart(lineItemId);
      } else {
        await modifyLineItem(lineItemId, { quantity: intQuantity });
      }
    } catch (error) {
      logSentryError(error, { source: 'CartQuantitySelector' });
    }
  };

  useEffect(() => setIsLoading(false), [quantity, lineItemId]);

  return (
    <StyledQuantitySelector>
      <button
        disabled={isLoading || quantity === 1}
        onClick={() => updateQuantity(quantity - 1)}
        className="qty-btn"
        aria-label="decrease"
      >
        <MinusSVG />
      </button>
      <span>{quantity}</span>
      <button
        disabled={isLoading}
        className="qty-btn"
        aria-label="increase"
        onClick={() => updateQuantity(quantity + 1)} 
      >
        +
      </button>
    </StyledQuantitySelector>
  );
};

interface CartQuantitySelectorProps {
  lineItemId: number;
  quantity: number;
}

export default CartQuantitySelector;
