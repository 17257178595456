import { useAnalytics, useCart } from '@chordcommerce/gatsby-theme-autonomy';
import { useMediaQuery } from '@mantine/hooks';
import { Link } from 'gatsby';
import React from 'react';
import useProductImagesQuery from '~/hooks/graphql/queries/use-product-images';
import { AMBASSADOR_ENROLLMENT } from '../../types/constants';
import CartQuantitySelector from '../Cart/QuantitySelector/QuantitySelector';
import { DisplayPrice } from '../DisplayPrice/DisplayPrice';
import { Cta } from '../Generic/Cta/Cta';
import { StyledCartItem } from './CartItem.style';

export const CartItem: React.FC<CartItemProps> = ({ data }) => {
  const { variant, id, quantity } = data;
  const { trackClickProduct } = useAnalytics();
  const { name, slug } = variant;
  const path = variant.sku.includes('|') ? 'bundles' : 'products';
  const url = `/${path}/${slug}`;
  const { removeFromCart } = useCart();
  const isSubscription = !!data.subscriptionLineItems?.length;
  const isAmbassadorProduct = variant.slug === AMBASSADOR_ENROLLMENT.slug;
  const productImages = useProductImagesQuery();
  const image = variant.images?.[0]?.smallUrl || (productImages[variant?.sku] ? `${productImages[variant?.sku]}?w=250` : '');
  const isMobile = useMediaQuery('(max-width: 768px)');

  const originalPrice = (data.price as any) * data.quantity;
  let finalPrice = originalPrice;

  (data.adjustments || []).filter(adj => adj?.sourceType?.includes?.('PromotionAction') && adj?.eligible).forEach(adj => finalPrice += (adj.amount as any) * 1);

  finalPrice = finalPrice >= originalPrice ? null : finalPrice;

  return (
    <StyledCartItem isEnrolment={isAmbassadorProduct}>
      <Link to={url} onClick={(e) => trackClickProduct(slug)} className="product-link">
        <div className="img-container">
          {image && (
            <img src={image} />
          )}
        </div>
      </Link>
      <div className="content">
        <div className="item-header">
          <div className="item-name">
            <Link to={url} className="product-link">
              <p>{name}</p>
            </Link>
            {isSubscription && (
              <p className="item-sub-text">
                Renews every{' '}
                {data.subscriptionLineItems[0].intervalLength > 1
                  ? data.subscriptionLineItems[0].interval
                  : data.subscriptionLineItems[0].intervalUnits}
              </p>
            )}
          </div>
          <DisplayPrice
            originalAmount={`${originalPrice}`}
            finalAmount={finalPrice ? `${finalPrice}` : null}
            inlinePrice={!isMobile}
          />
        </div>
        <div className="item-controls">
          {!isAmbassadorProduct && <CartQuantitySelector lineItemId={id} quantity={quantity} />}
          <Cta
            href="#"
            variant="secondary"
            onClick={() => removeFromCart(id)}
            className="remove-btn"
          >
            Remove
          </Cta>
        </div>
      </div>
    </StyledCartItem>
  );
};

interface CartItemProps {
  data: LineItem;
}
