import React from 'react';
import { useCartContext } from '../CartContext/CartContext';
import { Cta } from '../Generic/Cta/Cta';
import { Modal, ModalProps } from '../Modal/Modal';
import { StyledAmbassadorModal } from './AmbassadorModal.style';

export const AmbassadorModal: React.FC<AmbassadorModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
}) => {
  try {
    const { ambassadorProduct } = useCartContext();
    const parsedDescription = JSON.parse(ambassadorProduct.description.raw);
    const [{ value }] = parsedDescription.content[0].content;
    return (
      <Modal isOpen={isOpen} onClose={onClose}>
        <StyledAmbassadorModal>
          <p className="modal--title">{ambassadorProduct.shortDescription}</p>
          <p className="modal--content">{value}</p>
          <Cta onClick={onConfirm} href="#" variant="secondary">
            Proceed to Cart
          </Cta>
        </StyledAmbassadorModal>
      </Modal>
    );
  } catch (e) {
    return null;
  }
};

interface AmbassadorModalProps extends ModalProps {
  onConfirm: () => Promise<void>;
}
