import '../../styles/fonts.css';

import React, { PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import NotificationBar from '~/components/Notification/Bar/Bar';
import theme from '../../styles/theme';
import GlobalStyles from '../../styles/global.styled';
import { Header } from '../Header/Header';
import { Footer } from '../Footer/Footer';
import { CartConsumer, CartProvider } from '../CartContext/CartContext';
import { Panel } from '../Generic/Panel/Panel';
import { MiniCart } from '../MiniCart/MiniCart';
import { useMediaQuery } from '@mantine/hooks';
import { PanelOrigin } from '../../types/types';
import useUser from '~/hooks/components/use-user';
import ShopWithBanner from '../ShopWithBanner/ShopWithBanner';

const Layout: LayoutComponent = ({
  children,
  floatingHeader,
  showHeader = true,
  showFooterMarquee = true,
  showFooter = true,
  showMiniCart = true,
}) => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const panelOrigin: PanelOrigin = isMobile ? 'bottom' : 'right';
  const { user } = useUser();
  const isAmbassador = useMemo(() => {
    return user?.data?.roles?.includes('ambassador');
  }, [user]);
  const [ambassadorBannerVisible, setAmbassadorBannerVisible] = useState(true);
  const [hasCopied, setHasCopied] = useState(false);
  let path = typeof window !== 'undefined' ? window.location.pathname : '';
  const forbiddenPaths = ['/order', '/checkout', '/cart', '/account'];
  const isForbiddenPath = forbiddenPaths.some((p) => path.includes(p));
  return (
    <ThemeProvider theme={theme}>
      <CartProvider>
        <GlobalStyles />
        {showHeader && (
          <>
            <NotificationBar extraPadding={floatingHeader} />
            <Header floating={floatingHeader} />
          </>
        )}
        <ShopWithBanner hide={isAmbassador || isForbiddenPath} />
        <main className="mainContent">{children}</main>

        {showFooter && <Footer showMarquee={showFooterMarquee} />}
        {showMiniCart && (
          <CartConsumer>
            {({ closeCart, isCartOpen, forceAmbassadorInfo, shopWithName }) => (
              <>
                <Panel
                  isOpen={isCartOpen}
                  onClose={closeCart}
                  panelOrigin={panelOrigin}
                >
                  <MiniCart />
                </Panel>
                {!isForbiddenPath && (isAmbassador || forceAmbassadorInfo) && (
                  <div className={`AmbassadorBanner ${ambassadorBannerVisible ? 'is-visible' : 'is-hidden'}`}>
                    {hasCopied && <p>The URL has been copied to your clipboard!</p>}
                    {!hasCopied && <p>As an ambassador, you can share a unique link to this page to educate your audience or empower them to make a purchase.  <button onClick={() => {
                      const params = new URLSearchParams(window.location.search);
                      params.set('amref', forceAmbassadorInfo ? `${forceAmbassadorInfo}` : user?.data?.id);
                      const url = `${window.location.origin}${window.location.pathname}?${params.toString()}`;
                      navigator.clipboard.writeText(url);
                      setHasCopied(true);
                      setTimeout(() => {
                        setAmbassadorBannerVisible(true);
                      }, 3000);
                    }}>Click here to copy this URL</button> and paste it anywhere.</p>}
                    <button className="AmbassadorBanner__close" onClick={() => setAmbassadorBannerVisible(false)} aria-label="Close"></button>
                  </div>
                )}
              </>
            )}
          </CartConsumer>
        )}
        
      </CartProvider>
    </ThemeProvider>
  );
};

export default Layout;

interface LayoutProps {
  floatingHeader?: boolean;
  showHeader?: boolean;
  showFooter?: boolean;
  showFooterMarquee?: boolean;
  showMiniCart?: boolean;
}

type LayoutComponent = React.FC<PropsWithChildren<LayoutProps>>;
