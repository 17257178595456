import { graphql, useStaticQuery } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';
const useProductImagesQuery = (): Record<string, string> => {
  const data = useStaticQuery(
    graphql`
      query ProductsQuery {
        allContentfulKit {
          nodes {
            sku
            mainImage{
              url
            }
          }
        }
        allContentfulVariant{
          nodes {
            sku
            mainImage {
              url
            }
          }
        }
      }
    `
  );

  const returnValue: Record<string, string> = {};
  (data?.allContentfulKit?.nodes || []).filter((node: any) => node?.sku && node?.mainImage?.url).forEach((node: any) => {
    returnValue[node.sku] = node.mainImage.url;
  });
  (data?.allContentfulVariant?.nodes || []).filter((node: any) => node?.sku && node?.mainImage?.url).forEach((node: any) => {
    returnValue[node.sku] = node.mainImage.url;
  });

  return returnValue;
};

export default useProductImagesQuery;
