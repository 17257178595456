import React, { forwardRef, useEffect } from 'react';
import { StyledSharedCartSummary } from './SharedCartSummary.style';
import { Cta } from '~/components/Generic/Cta/Cta';
import LinkIcon from '~/assets/images/icons/link.svg';
import { useCartContext } from '~/components/CartContext/CartContext';
import { useClipboard } from '@mantine/hooks';

export const SharedCartSummary = forwardRef<HTMLDivElement,SharedCartSummaryProps>((props, ref) => {
  const { shareableLink } = useCartContext();
  const { copied, copy } = useClipboard({timeout: 5000});

  const handleClick = () => {
    copy(shareableLink);
  };

  return (
    <StyledSharedCartSummary className="sharedOrderSummary" ref={ref}>
      <div className="sharedOrderSummary__cta">
        <span className="sharedOrderSummary__cta__label">Share link below:</span>
        <Cta className="sharedOrderSummary__ctaButton" variant="secondary" onClick={handleClick}>Copy link</Cta>
      </div>

      <div className="sharedOrderSummary__link">
        <div className={["sharedOrderSummary__info", copied ? "sharedOrderSummary__info--active" : ''].join(' ')}>
          <span className="sharedOrderSummary__info__label">Link copied!</span>
        </div>
        <Cta variant="primary" onClick={handleClick}>
          <LinkIcon />
        </Cta>

        <span className="sharedOrderSummary__link__text" onClick={handleClick}>
          {shareableLink}
        </span>
      </div>
    </StyledSharedCartSummary>
  );
});

interface SharedCartSummaryProps {}
