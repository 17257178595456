import styled from 'styled-components';
import { pxtorem, desktopHover } from '~/utils/tools';

export const StyledMobileNav = styled.div`

.mobilenav__navs {
    padding: calc(var(--gutter) / 2);
    display: flex;
    justify-content: center;
    align-items: stretch;
  }

  .mobilenav__nav {
    display: flex;

    & > ul {
      list-style: none;
      display: flex;

      > li {
        &:not(:last-child) {
          margin-right: 1em;
        }

        &:hover {
          > a:before {
            content: '';
          }

          > a:after {
            transform-origin: left center;
            transform: scaleX(1);
          }
        }

        > a {
          position: relative;

          color: var(--black);
          font-weight: 500;
          text-decoration: none;

          transition: color 250ms;

          &:before {
            content: none;
            position: absolute;
            left: -20px;
            right: -20px;
            top: -10px;
            height: 60px;
          }

          &:after {
            transition: transform 0.25s var(--cubic-ease);
            content: '';
            position: absolute;
            bottom: -5px;
            left: 0;
            right: 0;
            height: 2px;
            background: currentColor;
            transform: scaleX(0);
            transform-origin: right center;
          }

          ${desktopHover(
            `
          color: var(--green);
        `,
            true
          )}
        }
      }
    }

    &--center {
      align-self: center;
      margin-inline: auto;
    }

    &--right {
      align-self: center;
      justify-self: flex-end;
      > ul li {
        padding-right: calc(var(--gutter) / 2);
      }

    }
    li svg {
      height: ${pxtorem(15)};
      width: auto;
      margin-top: 2px;
    }
  }

  .logo {
    width: 113px;

    a,
    svg {
      width: 100%;
      height: auto;
    }

    svg {
      transform: translateY(2px);
    }
  }

  .svgIcon {
    width: 40px;
    height: 40px;
    padding: 0;
    appearance: none;
    outline: 0;
    border: none;
    background: transparent;
    cursor: pointer;
    transition: 0.25s color;
    svg {
      color: var(--black);
      width: 100%;
      height: 100%;
    }
    ${desktopHover(`color: var(--green)`)};

    &--close {
      svg {
        width: 11px;
        height: 11px;
      }
    }
  }
`;

export const StyledMobilePanel = styled.div`
  --topPos: 56px;
  position: absolute;
  top: var(--topPos);
  left: 0;
  height: calc(100vh - var(--topPos));
  width: 100%;
  padding: 0 3rem;
  background: var(--white);
  z-index: 20;
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  transition: opacity 250ms, visibility 0ms 250ms;
  overflow: auto;

  &.mobilenav--opened {
    pointer-events: auto;
    visibility: visible;
    opacity: 1;
    transition-delay: 0ms;
  }

  .submenu__separator {
    margin: 1rem 0;
    height: 1px;
    background: var(--gray);
  }

  & ul:first-child {
    list-style: none;

    > li {
      margin: 1rem 0;

      &:hover {
        > a:before {
          content: '';
        }

        > a:after {
          transform-origin: left center;
          transform: scaleX(1);
        }
      }

      > a {
        position: relative;

        color: var(--black);
        font-weight: 500;
        font-size: ${pxtorem(16)};
        text-decoration: none;

        transition: color 250ms;

        &:before {
          content: none;
          position: absolute;
          left: -20px;
          right: -20px;
          top: -10px;
          height: 60px;
        }

        &:after {
          transition: transform 0.25s var(--cubic-ease);
          content: '';
          position: absolute;
          bottom: -5px;
          left: 0;
          right: 0;
          height: 2px;
          background: currentColor;
          transform: scaleX(0);
          transform-origin: right center;
        }

        ${desktopHover(
          `
          color: var(--green);
        `,
          true
        )}
      }
    }
  }
`;
