import styled from 'styled-components';
import { pxtorem } from '~/utils/tools';

export const StyledDisplayPrice = styled.div<StyledDisplayPriceProps>`
  position: relative;
  display: block;

  p {
    ${({ size }) =>
      size === 'large'
        ? `
      @media (min-width: 768px) {
        font-size: ${pxtorem(20)};
      }
    
   `
        : ''}

    &:before {
      content: '${({ originalAmount }) => originalAmount}';
      color: var(--red);
      text-decoration: line-through;
      margin-right: ${p => p.inlinePrice ? '8px' : '0'};
      display: ${({ originalAmount, showMarkdown }) => (showMarkdown ? 'unset' : 'none')};
      position: ${p => p.inlinePrice ? 'static' : 'absolute'};
      left: ${p => p.inlinePrice ? 'calc(-100% - 14px)' : '-2px'};
      top: ${p => !p.inlinePrice ? 'calc(100%)' : 'unset'};
    }
  }
`;

interface StyledDisplayPriceProps {
  originalAmount?: string;
  showMarkdown?: boolean;
  size?: 'large';
  inlinePrice?: boolean;
}
