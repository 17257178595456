import styled from 'styled-components';
import { pxtorem } from '../../../utils/tools';

export const StyledCheckbox = styled.label<StyledCheckboxProps>`
  --checksize: ${pxtorem(20)};
  display: flex;
  align-items: center;
  cursor: pointer;

  * {
    margin: 0;
    /* padding: 0; */
  }

  input[type='checkbox'] {
    position: relative;
    margin-right: 10px;
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    margin: 0;
    padding: 0;
    cursor: pointer;
  }

  input[type='checkbox']::before {
    content: '';
    left: 0;
    top: 0;
    transform: translateY(-50%);
    position: absolute;
    width: var(--checksize);
    height: var(--checksize);
    border: 1px solid var(--black);
  }

  input[type='checkbox']:checked {
    &:before {
      background-color: var(--green);
      border-color: var(--green);
    }

    &:after {
      // Checkmark
      content: '';
      position: absolute;
      top: -3px;
      left: 4px;
      border-left: 2px solid var(--white);
      border-bottom: 2px solid var(--white);
      height: 5px;
      width: 12px;
      transform: rotate(-45deg);
    }
  }

  ${({ isInvalid }) => isInvalid && `
    input[type='checkbox']:invalid::before {
      border-color: var(--red);
    } 
    `};

  span {
    margin-left: ${pxtorem(32)};
  }
`;

interface StyledCheckboxProps {
  isInvalid: boolean;
}
