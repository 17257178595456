import styled from 'styled-components';
import { Section } from '~/styles/Section.styled';

export const StyledHeader = styled.header<StyledHeaderProps>`
  position: relative;
  
  ${({ floating, fixed }) =>
    floating &&
    !fixed &&
    `
       @media (min-width: 900px) {
        position: absolute;
        width: 100%;
        top: calc(var(--shop-with-height) + 21px);
      }
  `}

  ${({ floating, fixed }) =>
    !floating &&
    !fixed &&
    `
    ${Section} {
      padding-top: 0;
      padding-bottom: 0;
    }
  `}

  ${({ fixed }) =>
    fixed &&
    `
      position: fixed;
      width: 100%;
      top: var(--shop-with-height);
      z-index: 20;
    `}
`;

interface StyledHeaderProps {
  floating?: boolean;
  fixed?: boolean;
}
