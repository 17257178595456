import React from 'react';
import useNotificationBar from '~/hooks/components/use-notification-bar';
import { Section } from '../../../styles/Section.styled';
import { Wrapper } from '../../../styles/Wrapper.styled';

import { StyledNotificationBar } from './Bar.style';

const NotificationBar: React.FC<NotificationProps> = ({ extraPadding }) => {
  const { loaded, notification } = useNotificationBar();

  if (!notification) return null;

  const content = (
    <StyledNotificationBar extraPadding={extraPadding}>
      <Section bg="green" color="white">
        <Wrapper gutter={false}>
          <div
            dangerouslySetInnerHTML={{
              __html:
                notification.description?.childMarkdownRemark?.html ||
                notification.description,
            }}
          />
        </Wrapper>
      </Section>
    </StyledNotificationBar>
  );

  return notification.href ? (
    <a href={notification.href}>{content}</a>
  ) : (
    content
  );
};

interface NotificationProps {
  extraPadding?: boolean;
}

export default NotificationBar;
