import React from 'react';
import { StyledDisplayPrice } from './DisplayPrice.style';

export const DisplayPrice: React.FC<DisplayPriceProps> = ({
  originalAmount,
  finalAmount,
  size,
  inlinePrice = true
}) => {
  const originalHasDollarSign = originalAmount && originalAmount.includes('$');

  const formattedOriginal =
    originalAmount &&
    `$${(originalHasDollarSign
      ? +originalAmount.substring(1)
      : +originalAmount
    ).toFixed(2)}`;

  const formattedFinal = finalAmount ? `$${(+finalAmount).toFixed(2)}` : `$${(+originalAmount).toFixed(2)}`;

  return (
    <StyledDisplayPrice
      size={size}
      showMarkdown={finalAmount !== null}
      originalAmount={originalAmount && formattedOriginal}
      inlinePrice={inlinePrice}
    >
      <p>
        <span>{formattedFinal}</span>
      </p>
    </StyledDisplayPrice>
  );
};

interface DisplayPriceProps {
  originalAmount?: string;
  finalAmount: string;
  size?: 'large';
  inlinePrice?: boolean;
}
